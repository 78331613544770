import React, { useState } from 'react';
import { userRequest } from '../utils/requestMethods';
import { Header, ButtonLoading } from '../components';
import { useStateContext } from '../contexts/ContextProvider';

const AddclassName = ({ history }) => {
    const { school } = useStateContext();
    const schoolId = school?._id;

    const [addonName, setAddonName] = useState("");
    const [addonPrice, setAddonPrice] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    //console.log(checked);
    const submitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!addonName || !addonPrice) {
            setError("Please Fill all the Feilds");
            setTimeout(() => {
                setError("");
            }, 5000);
            setLoading(false);
            return;
        }

        try {
            await userRequest.post("/add-on",
                {
                    schoolId,
                    add_on_name: addonName,
                    add_on_price: addonPrice,
                });

            //console.log(data);
            //history.push("/classes");
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
                setAddonName("");
                setAddonPrice("");
            }, 5000);
        } catch (error) {
            console.log(error);
            setError(error.response.data);
            setTimeout(() => {
                setError("");
            }, 5000);
            setLoading(false);
        }
        setLoading(false);
    };
    return (
        <div className="m-2 md:m-10 mt-24  p-2 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
            <Header category="Set Up" title="Add Extra Fee" />
            <div className="py-2 items-right">
                <div className="mt-2 max-w-full">
                    <form onSubmit={submitHandler}>
                        <div className="grid grid-cols-1 gap-6">
                            {error &&
                                <div className="alert alert-warning d-flex align-items-center" role="alert">
                                    <i className="fal fa-lock pr-10"></i>
                                    <div>
                                        {error}
                                    </div>
                                </div>
                            }
                            {success &&
                                <div className="alert alert-success d-flex align-items-center" role="alert">
                                    <i className="fal fa-lock pr-10"></i>
                                    <div>
                                        New Add-on Added successfully!
                                    </div>
                                </div>
                            }

                            <label className="block">
                                <span className="text-gray-700 dark:text-gray-200">Extra-Fee Name <small>(Lesson, Excursion, Uniform, etc)</small></span>
                                <input type="text" onChange={(e) => setAddonName(e.target.value)} value={addonName} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder="Add-on Name" />
                            </label>
                            <label className="block">
                                <span className="text-gray-700 dark:text-gray-200">Extra-Fee Price</span>
                                <input type="number" onChange={(e) => setAddonPrice(e.target.value)} value={addonPrice} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder="e.g 20000" />
                            </label>
                            <div className="px-4 py-3 text-right sm:px-6">
                                {loading ?
                                    (
                                        <ButtonLoading styles="inline-flex justify-center py-3 px-8 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-600" />
                                    ) : (
                                        <button type="submit" className="inline-flex justify-center py-3 px-8 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Save Fee</button>
                                    )
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
export default AddclassName;
