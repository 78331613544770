import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { ImSpinner } from "react-icons/im";
import { userRequest, formatter } from '../utils/requestMethods';
import { Header } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { MdKeyboardArrowRight } from 'react-icons/md';

const customStyles = {
  headRow: {
    style: {
      backgroundColor: 'rgb(248, 250, 252)',
      fontWeight: "bold",
    },
  },
};

const Addons = ({ history }) => {
  const location = useLocation();
  const classId = location.pathname.split('/')[2];
  const className = location.pathname.split('/')[3];
  const { school } = useStateContext();
  const [addons, setAddons] = useState();
  const [loading, setLoading] = useState(true);
  const schoolId = school?._id;
  //const schoolCode = school?.code;

  useEffect(() => {
    const fetchAddons = async () => {
      if (schoolId) {
        try {
          setLoading(true);
          const { data } = await userRequest.get(`class-addon/${schoolId}/${classId}`);
          //console.log(data.add_on);
          setAddons(data.add_on);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchAddons();
  }, [classId, schoolId]);

  const goBack = () => {
    // Go back to the previous page
    history.goBack();
  };

  const columns = [
    {
      name: "Extra Fee",
      selector: row => <span className="font-bold">{row?.add_on_name}</span>
    },
    {
      name: "Class Name",
      selector: row => className
    },
    {
      name: "Price",
      selector: row => <button type="button" style={{ background: 'rgb(139, 231, 139)' }} className="text-white py-1 px-2 capitalize rounded-2xl text-md font-bold">{formatter.format(row?.add_on_price)}</button>
    },
    {
      name: "Options",
      selector: (row) => <Link to={`/addon-payments/students/${row?._id}/${row?.add_on_name}`}><button type="button" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">View Details <MdKeyboardArrowRight /></button></Link>,
    },
  ];

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 dark:bg-secondary-dark-bg bg-white rounded-3xl">
      <button onClick={goBack} className="inline-flex justify-center py-2 px-6 mb-2 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"><i class="fad fa-arrow-left"></i></button>
      <Header category="Payments" title={className + " Extra Fees"} />
      
      <DataTable
        data={addons}
        columns={columns}
        getRowId={(row) => row._id}
        pagination
        customStyles={customStyles}
        progressPending={loading}
        progressComponent={
          <ImSpinner className="animate-spin text-4xl text-gray-400" />
        }
      />
    </div>
  );
};
export default Addons;
