import React, { useState, useEffect } from "react";
import { Header, Footer } from "../../components";
import { userRequest } from "../../utils/requestMethods";
import { useStateContext } from '../../contexts/ContextProvider';
import {
  Information,
  Address,
  People,
  Account,
  Document,
} from "../../components";

const Onboarding = ({ history }) => {
  const { school } = useStateContext();
  //const userId = location.pathname.split("/")[2];
  const [openTab, setOpenTab] = React.useState(6);
  const [refresh, setRefresh] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const { data } = await userRequest.get(`schools/${school._id}`);
        //console.log(data);
        setUserInfo(data);
        //console.log(data, "user details");
      } catch (error) {
        console.log(error);
      }
    };
    fetchTransactions();
  }, [school, refresh]);

  const statusHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { data } = await userRequest.get(`/schools/${school._id}/kyc-review-request`);
      console.log(data);
      setSuccess("KYB has been submitted for review!");
      setTimeout(() => {
        setSuccess("");
        history.push("/onboarding/status");
      }, 3000);
    } catch (error) {
      console.log(error);
      setError("Something went wrong, try again later!");
      setTimeout(() => {
        setError("");
      }, 3000);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="m-3 mt-4 p-2 md:p-10 bg-white rounded-2xl dark:bg-secondary-dark-bg">
        {success && (
          <div className="alert alert-success d-flex align-items-center" role="alert">
              <i className="fal fa-check pr-10 text-bold" style={{ fontWeight: "bold" }}></i>
              <div>{success}</div>
          </div>
        )}
        {error && (
          <div className="alert alert-warning d-flex align-items-center" role="alert">
            <i className="fal fa-lock pr-10 text-bold" style={{ fontWeight: "bold" }}></i>
            <div>{error}</div>
          </div>
        )}
        <button
          type="button"
          onClick={statusHandler}
          disabled={loading}
          style={{ backgroundColor: "blue" }}
          className="inline-flex mx-2 justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 float-right"
        >
          {loading ? "loading" : "Submit for approval"}
        </button>
        <Header category="Compliance" title="Activate your business" />
        <ul
          className="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row sm:pt-10"
          role="tablist"
        >
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a
              className={
                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                (openTab === 6
                  ? "text-white bg-blue-600"
                  : "text-gray-700 bg-white")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(6);
                setRefresh(6);
              }}
              data-toggle="tab"
              href="#link6"
              role="tablist"
            >
              Business Details
            </a>
          </li>
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a
              className={
                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                (openTab === 2
                  ? "text-white bg-blue-600"
                  : "text-gray-700 bg-white")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(2);
                setRefresh(2);
              }}
              data-toggle="tab"
              href="#link2"
              role="tablist"
            >
              Address
            </a>
          </li>
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a
              className={
                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                (openTab === 3
                  ? "text-white bg-blue-600"
                  : "text-gray-700 bg-white")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(3);
                setRefresh(3);
              }}
              data-toggle="tab"
              href="#link3"
              role="tablist"
            >
              Owners
            </a>
          </li>
          {/*<li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a
              className={
                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                (openTab === 4
                  ? "text-white bg-blue-600"
                  : "text-gray-700 bg-white")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(4);
                setRefresh(4);
              }}
              data-toggle="tab"
              href="#link4"
              role="tablist"
            >
              Account
            </a>
          </li>
          <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
            <a
              className={
                "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                (openTab === 5
                  ? "text-white bg-blue-600"
                  : "text-gray-700 bg-white")
              }
              onClick={(e) => {
                e.preventDefault();
                setOpenTab(5);
                setRefresh(5);
              }}
              data-toggle="tab"
              href="#link5"
              role="tablist"
            >
              Documents
            </a>
            </li>*/}
        </ul>
        <div className="px-2 py-5 flex-auto">
          <div className="tab-content tab-space">
            {/*<div className={openTab === 1 ? "block" : "hidden"} id="link1">
              <Contact social={userInfo.socials} email={userInfo.email} />
            </div>*/}
            <div className={openTab === 2 ? "block" : "hidden"} id="link2">
              <Address setRefresh={setRefresh} address={userInfo.address} />
            </div>
            <div className={openTab === 3 ? "block" : "hidden"} id="link3">
              <People setRefresh={setRefresh} official={userInfo.officials} />
            </div>
            <div className={openTab === 4 ? "block" : "hidden"} id="link4">
              <Account setRefresh={setRefresh} accountInfo={{ account_name: userInfo.account_name, account_number: userInfo.account_number, bank_code: userInfo.bank_code }} />
            </div>
            <div className={openTab === 5 ? "block" : "hidden"} id="link5">
              <Document />
            </div>
            <div className={openTab === 6 ? "block" : "hidden"} id="link6">
              <Information setRefresh={setRefresh} info={userInfo} />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Onboarding;
