import React, { useState, useEffect } from 'react';
import { userRequest } from '../utils/requestMethods';
import { Header, ButtonLoading } from '../components';
import { useStateContext } from '../contexts/ContextProvider';

const Withdraw = () => {
  const { user, school } = useStateContext();
  const userId = user?._id;
  const schoolID = school?._id;

  const [balance, setBalance] = useState({});
  const [transactions, setTransactions] = useState([]);
  const [bankDetails, setBankDetails] = useState({});

  const [amount, setAmount] = useState("");
  const [recipientCode, setRecipientCode] = useState("");
  const [password, setPassword] = useState("");
  //console.log(accessCode);
  //console.log(recipientCode);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const fetchBalance = async () => {

      try {
        const res = await userRequest.get(`schools/${schoolID}/balance`);
        //console.log(res.data);
        setBalance(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchBalance();
  }, [schoolID]);

  useEffect(() => {
    const fetchTransactions = async () => {
      
      try {
        const { data } = await userRequest.get(`transactions/${userId}/${schoolID}?type=withdrawal&sort_by=date&order=des`);
          //console.log(data);
          setTransactions(data.transactions);
      } catch (error) {
          console.log(error);
      }
    };

    fetchTransactions();
  }, [userId, schoolID]);
  //fetch banks detais
  useEffect(() => {
    const fetchDetails = async () => {
      
      try {
        const res = await userRequest.get(`banks/${schoolID}`);
        //console.log(res.data);
        setBankDetails(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchDetails();
  }, [schoolID]);

const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!amount || !recipientCode || !password) {
      setError("Please fill all the feilds");
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
      return;
    }
    
    try {
      await userRequest.post(`banks/${schoolID}/transfer`,
        { 
          amount,
          recipient_code: recipientCode, 
          password,
          reason: "witdrawal",
        });
      //console.log(data);
      //history.push("/classes");
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    } catch (error) {
      console.log(error);
      setError(error.response.data);
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <div className="m-4 md:m-10 mt-24 p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
      <Header category="Financing" title="Make Withdrawal" />
      <div class="mt-2 max-w-full divide-y">
        <div class="py-8">
          <p class="text-md text-gray-600 dark:text-gray-200">
            Available Balance
          </p>
          <h1 class="text-4xl font-bold dark:text-gray-200">₦ {balance.balance}</h1>
          <p class="mt-2 text-lg text-gray-600 dark:text-gray-200">
            This is where you'll be able to see your Invoice payouts and invoice financing.
          </p>
        </div>
      </div>
      <hr />
      <div class="max-w-xl mx-auto mt-3 divide-y">
        <form onSubmit={submitHandler}>
          <div class="grid grid-cols-1 gap-6">
            {error &&
                <div className="alert alert-warning d-flex align-items-center" role="alert">
                  <i className="fal fa-times pr-10"></i>
                  <div>
                    {error}
                  </div>
                </div>
              }
              {success &&
                <div className="alert alert-success d-flex align-items-center" role="alert">
                  <i className="fal fa-check pr-10"></i>
                  <div>
                    <b>Success!</b> withdrawal is being processed
                  </div>
                </div>
              }
            <label class="block">
              <span class="text-gray-700 dark:text-gray-200">Amount</span>
              <input type="number" onChange={(e) => setAmount(e.target.value)} autoComplete="number" class="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder="1000" />
            </label>
            <p class="text-lg text-gray-600 dark:text-gray-200">
              Choose account
            </p>
            <label class="block">
              <input className="m-radio-input" type="radio" value={bankDetails?.recipient_code} onChange={(e) => setRecipientCode(e.target.value)} id="account" required />
              <label className="m-radio-label ml-1" htmlFor="account">
                {bankDetails?.account_name}
              </label>
            </label>
            <label class="block">
              <span class="text-gray-700 dark:text-gray-200">Password</span>
              <input type="password" onChange={(e) => setPassword(e.target.value)} class="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
            </label>
            <div class="px-4 py-3 text-right sm:px-6">
              {loading ?
                (
                  <ButtonLoading styles="inline-flex justify-center py-3 px-8 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-600" />
                ) : (
                  <button type="submit"  class="inline-flex justify-center py-3 px-8 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Withdraw</button>
                )
              }
            </div>
          </div>
        </form>
      </div>
      <hr />
      <div class="py-4 overflow-scroll md:overflow-hidden">
        <p class="text-lg text-gray-600 dark:text-gray-200">
          Recent Transactions
        </p>
          <table class="border-collapse table-auto w-full border border-slate-300 dark:border-slate-500 bg-white dark:bg-slate-800 text-sm rounded-3xl">
            <thead class="bg-slate-50 dark:bg-slate-700">
              <tr>
                <th class="border-b border-slate-300 dark:border-slate-600 font-semibold p-4 text-slate-900 dark:text-slate-200 text-left">Amount</th>
                <th class="border-b border-slate-300 dark:border-slate-600 font-semibold p-4 text-slate-900 dark:text-slate-200 text-left">Transaction Fee</th>
                <th class="border-b border-slate-300 dark:border-slate-600 font-semibold p-4 text-slate-900 dark:text-slate-200 text-left">Final Amount Paid</th>
                <th class="border-b border-slate-300 dark:border-slate-600 font-semibold p-4 text-slate-900 dark:text-slate-200 text-left">Status</th>
                <th class="border-b border-slate-300 dark:border-slate-600 font-semibold p-4 text-slate-900 dark:text-slate-200 text-left">Payment Date</th>
              </tr>
            </thead>
            <tbody>
              {transactions ? (
                  transactions?.map((transaction) => (
                    <tr key={transaction._id}>
                        <td className="border-b border-slate-300 dark:border-slate-700 p-3 text-slate-500 dark:text-slate-400">₦ {transaction.amount}</td>
                        <td className="border-b border-slate-300 dark:border-slate-700 p-3 text-slate-500 dark:text-slate-400">₦ {transaction.transaction_fee}</td>
                        <td className="border-b border-slate-300 dark:border-slate-700 p-3 text-slate-500 dark:text-slate-400"><b>₦ {transaction.final_amount}</b></td>
                        <td className="border-b border-slate-300 dark:border-slate-700 p-3 text-slate-500 dark:text-slate-400">
                            <button type="button" style={{ background: 'rgb(139, 231, 139)' }} className="text-white py-1 px-2 capitalize rounded-2xl text-md font-bold">
                                {transaction.status}
                            </button>
                        </td>
                        <td className="border-b border-slate-300 dark:border-slate-700 p-3 text-slate-500 dark:text-slate-400">
                          {(new Date(transaction.paid_at)).toLocaleDateString('en-NG')}
                        </td>
                    </tr>
                  ))
              ) : (
                <tr>
                    <td className="border-b border-slate-300 text-align-center dark:border-slate-700 p-2 text-slate-500 dark:text-slate-400">
                        Nothing to display yet...
                    </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
    </div>
  )
};

export default Withdraw;
