import React from "react";
import Cookies from 'js-cookie';
import logo from "../../data/logo/logo.png";
import { useStateContext } from '../../contexts/ContextProvider';

const Completed = ({ history }) => {
  const { user, school } = useStateContext();

  const logoutHandler = () => {
    Cookies.remove('accessToken');
    localStorage.removeItem("tokenWithUser");
    history.push("/signin");
  };

  return (
    <>
    <div className="px-4 py-3 sm:px-6 mt-6 mb-4">
      <div className="flex items-center space-x-4">
        <div className="flex-1 min-w-0">
          <img src={logo} alt="" width="180" />
        </div>
        <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
          <p className="text-base font-semibold text-gray-500">
            <i class="fad fa-user" style={{"--fa-primary-color": "#6366f1", "--fa-secondary-color": "#6366f1",}}></i> {user?.email}
            <i class="fad fa-power-off mx-2" onClick={logoutHandler} style={{"--fa-primary-color": "#6366f1", "--fa-secondary-color": "#6366f1",}}></i>
          </p>
        </div>
      </div>
    </div>
    <div className="m-3 mt-4 p-2 md:p-10 bg-white rounded-2xl dark:bg-secondary-dark-bg">
      {/*<Header category="Compliance" title="Activate your business" />*/}
        <div className="mt-2 mb-4 max-w-2xl mx-auto p-8 bg-white border border-gray-200 rounded-sm shadow dark:bg-gray-800 dark:border-gray-700">
          <div className="flex flex-col items-center pb-6 pt-6">
            <i className="fad fa-user-clock text-6xl mb-3" style={{"--fa-primary-color": "#6366f1", "--fa-secondary-color": "#1a5fb4",}}></i>
          </div>
          <div className="items-center justify-between text-center mt-4 mb-4">
            <h5 className="text-xl font-bold leading-none text-gray-900 dark:text-white">Your Account is Undergoing a Human Review Process.</h5>
            <p className="text-base font-semibold text-gray-600 pt-3">We will get back to you, within 24- 48 hours</p>
            <div className="flex items-center space-x-4">
              <div className="flex-1 min-w-0">
                <p className="text-base font-semibold text-gray-500 truncate dark:text-white">
                  Status: {<span className={`text-base font-semibold ${school?.kyc_status === "rejected" ? "text-red-700" : school?.kyc_status === "approved" ? "text-blue-400": "text-yellow-400"} capitalize`}>
                  {school?.kyc_status === "rejected" ? "Rejected" : school?.kyc_status === "approved" ? "Approved": "Pending"}
                </span>}
                </p>
              </div>
            </div>
          </div>
          <div className="flow-root">
            <ul className="divide-y divide-gray-200 dark:divide-gray-700">
              <li className="py-3 sm:py-4">
                <div className="flex items-center space-x-4">
                  <div className="flex-1 min-w-0">
                    <p className="text-base font-semibold text-gray-500 truncate dark:text-white">
                      School Information
                    </p>
                  </div>
                  <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    Under Review
                  </div>
                </div>
              </li>
              <li className="py-3 sm:py-4">
                <div className="flex items-center space-x-4">
                  <div className="flex-1 min-w-0">
                    <p className="text-base font-semibold text-gray-500 truncate dark:text-white">
                      Address Information
                    </p>
                  </div>
                  <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    Under Review
                  </div>
                </div>
              </li>
              <li className="py-3 sm:py-4">
                <div className="flex items-center space-x-4">
                  <div className="flex-1 min-w-0">
                    <p className="text-base font-semibold text-gray-500 truncate dark:text-white">
                      Owners and Directors Information
                    </p>
                  </div>
                  <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                    Under Review
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="px-4 py-3 sm:px-6">
          <div className="flex items-center space-x-4">
            <div className="flex-1 min-w-0">
              <p className="text-xl font-semibold text-gray-600 truncate dark:text-white">
                Need help?
              </p>
              <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                Our Onboarding personnel will answer your questions
              </p>
            </div>
            <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
              <p className="text-base font-semibold text-gray-500">
                Support@acceede.com
              </p>
            </div>
          </div>
        </div>
    </div>
    </>
  )
}

export default Completed