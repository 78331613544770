import React from 'react'
import ButtonLoading from './ButtonLoading'

const AddFeeModal = ({ closeModal, addonName, setAddonName, addonPrice, setAddonPrice, submitHandler, loading, error, success }) => {
  return (
    <div>
    <div
      className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-md"
      onClick={closeModal}>
    </div>

    <div
      id="authentication-modal"
      tabIndex="-1"
      aria-hidden="true"
      className="overflow-y-auto overflow-x-hidden fixed top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 w-full md:w-[100%] max-w-md h-[calc(100%-1rem)] max-h-full"
    >
      <div className="relative p-4 w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
              Add extra fee
            </h3>
            <button type="button"
              className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="authentication-modal"
              onClick={closeModal}
            >
              <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="p-4 md:p-5">
            {error &&
              <div className="alert alert-warning d-flex align-items-center" role="alert">
                <i className="fal fa-lock pr-10"></i>
                <div>
                  {error}
                </div>
              </div>
            }
            {success &&
              <div className="alert alert-success d-flex align-items-center" role="alert">
                <i className="fal fa-lock pr-10"></i>
                <div>
                  New Add-on Added successfully!
                </div>
              </div>
            }
            <form onSubmit={submitHandler} className="space-y-4">
              <div>
                  <label for="class" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Extra-Fee Name</label>
                  <input type="text" onChange={(e) => setAddonName(e.target.value)} value={addonName} placeholder="Extra-Fee Name" className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
              </div>
              <div>
                  <label for="price" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Price</label>
                  <input type="number" onChange={(e) => setAddonPrice(e.target.value)} value={addonPrice} placeholder="e.g 20000" className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
              </div>
              {loading ?
                (
                  <ButtonLoading styles="w-full text-white bg-blue-500 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center" />
                ) : (
                  <button type="submit" className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Save Record</button>
                )
              }
            </form>
          </div>
        </div>
      </div>
    </div> 
  </div>
  )
}

export default AddFeeModal