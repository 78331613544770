import React, {useState} from 'react'
///import Cookies from 'js-cookie';
import cashflow from '../data/cashflow04.png';
import { useHistory } from "react-router-dom";
import { userRequest } from '../utils/requestMethods';

const AddClassModal = ({ closeModal, schoolId }) => {
    const history = useHistory();
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    //const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    const submitHandler = async (e) => {
        e.preventDefault();
        /*setLoading(true);
        
        setTimeout(() => {
            Cookies.remove('accessToken');
            localStorage.removeItem("tokenWithUser");
            history.push("/signin");
        }, 2000);*/
        try {
            setSaving(true);
            const { data } = await userRequest.post(`/schools/${schoolId}/validate-directors-identity`);
            console.log(data);
            setSuccess('Document submitted successfully!');
            setTimeout(() => {
                setSuccess("");
                history.push("/onboarding/get-started");
            }, 2000);
        } catch (error) {
            console.error('Error validating document', error);
            setError('Error submitting document, try again later');
            setTimeout(() => {
                setError("");
            }, 2000);
        } finally {
            setSaving(false);
        }
      };
  return (
    <div>
        <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-md" onClick={closeModal}></div>
        <div id="authentication-modal" tabIndex="-1" aria-hidden="true" className="overflow-y-auto overflow-x-hidden fixed top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 w-full md:w-[100%] max-w-md h-[calc(100%-1rem)] max-h-full">
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    {error && (
                        <div className="alert alert-warning flex items-center" style={{ paddingTop: "5px", paddingBottom: "5px"}} role="alert">
                            <i className="fal fa-lock pr-2"></i>
                            <div>{error}</div>
                        </div>
                    )}
                    {success && (
                        <div className="alert alert-success flex items-center" style={{ paddingTop: "5px", paddingBottom: "5px"}} role="alert">
                            <i className="fal fa-check pr-2"></i>
                            <div>{success}</div>
                        </div>
                    )}
                    <div className="p-4 md:p-5 text-center">
                        <img className="mx-auto rounded-full h-24 w-24" src={cashflow} alt="icon"/>
                        <h3 className="mb-5 text-lg font-bold text-gray-700 dark:text-gray-400">Your business documents will be sent in for validation</h3>
                        <p className="mb-7 text-base leading-relaxed text-gray-500 dark:text-gray-400">
                            Are you sure you have provided all required documents?
                        </p>
                        {saving ? (
                            <button data-modal-hide="popup-modal" type="button" className="mr-5 text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                <svg className="motion-reduce:hidden animate-spin -ml-1 mr-3 h-5 w-5 text-white" style={{ display: "inline" }} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Saving
                            </button>
                        ) : (
                            <button onClick={submitHandler} data-modal-hide="popup-modal" type="button" className="mr-5 text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center">
                                Yes, I'm sure
                            </button>
                        )}
                        <button onClick={closeModal} data-modal-hide="popup-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                            No, cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AddClassModal