import React from 'react';
import { Link } from 'react-router-dom';
//import { formatter } from '../../utils/requestMethods';

const Document = ({ mls }) => {
    //console.log(mls?.Document_pattern);
    
    return (
        <div>
            <div className="mt-2 max-w-xl mx-auto divide-y">
                <div className="py-2">
                    <h3 className="text-2xl font-bold dark:text-gray-200">Activate Your Business.</h3>
                    <p>
                        Based on your business type, you will be required to submit the documents below during the business activation process.
                    </p>
                    <hr />
                </div>
            </div>
            <div className="py-2 items-right">
                <div className="max-w-xl mx-auto mt-3 divide-y">
                    <form>
                        <div className="grid grid-cols-1 gap-6">
                            <div>
                                <div className="lg:grid lg:grid-cols-2 lg:gap-x-2">
                                    <div className="mb-2">
                                        <p className="text-gray-700 dark:text-gray-200">Business Registration Number</p>
                                        <input type="text" placeholder="XXXXXXX" className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                                    </div>
                                    <div className="mb-2">
                                        <p className="text-gray-700 dark:text-gray-200">Tax ID</p>
                                        <input type="text" placeholder="XXXXXXX" className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                                    </div>
                                </div>
                            </div>
                            <label className="block">
                                <span className="text-gray-700 dark:text-gray-200">Memorandum and Articles of Association</span>
                                <input className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" aria-describedby="user_avatar_help" id="user_avatar" type="file" />
                            </label>
                            <label className="block">
                                <span className="text-gray-700 dark:text-gray-200">Certificate of Incorporation/Registration</span>
                                <input className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" aria-describedby="user_avatar_help" id="user_avatar" type="file" />
                                <div className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="user_avatar_help">A copy of your business registration document</div>
                            </label>
                            <label className="block">
                                <span className="text-gray-700 dark:text-gray-200">Form CAC 1.1 / CAC Status Report</span>
                                <input className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" aria-describedby="user_avatar_help" id="user_avatar" type="file" />
                                <div className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="user_avatar_help">Status of application for registration of a company with CAC</div>
                            </label>
                            
                            <div className="px-4 py-3 text-right sm:px-6">
                                <button type="submit" className="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Save Details</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <hr />
            <div className="px-4 py-3 sm:px-6 mb-2">
                <Link to="/compliance/payment">
                    <button className="float-right inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Next</button>
                </Link>
                <Link to="/compliance/personal">
                    <button className="float-left inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Back</button>
                </Link>
            </div>
        </div>
    )
}

export default Document
