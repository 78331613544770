import React, { useState, useEffect } from 'react'
import { useFormik } from "formik";
import { informationSchema } from '../../utils/formSchema';
import { formatDate } from '../../utils/dateUtils';
import { userRequest } from '../../utils/requestMethods';
import { useStateContext } from '../../contexts/ContextProvider';
import ButtonLoading from '../ButtonLoading';

const Information = ({ setRefresh, info }) => {
    const { school } = useStateContext();
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    /*const [initialValues, setInitialValues] = useState({
        school_name: "",
        phone_number: "",
        bvn: "",
        date_of_registration: "",
        registration_type: "",
        industry: "",
        description: "",
    });*/

    console.log(info);

    useEffect(() => {
        formik.setValues({
            ...info,
            date_of_registration: formatDate(info.date_of_registration),
        });
    }, [info]);
    /*useEffect(() => {
        // Update initialValues when apiResponse changes
        if (info) {
        formik.setValues({
            school_name: info.school_name || "",
            phone_number: info.phone_number || "",
            bvn: info.bvn || "",
            date_of_registration: info.date_of_registration || "",
            registration_type: info.registration_type || "",
            industry: info.industry || "",
            description: "" || info.description,
        });
        console.log("ran!")
        }
    }, [info]);*/

    const formik = useFormik({
        initialValues: {
            school_name: '',
            phone_number: '',
            bvn: '',
            date_of_registration: '',
            registration_type: '',
            industry: '',
            description: '',
            ...info,
        },
        validationSchema: informationSchema,
        onSubmit: (values) => {
        // Handle form submission here
        registerHandler(values);
        console.log(values);
        },
    });
    console.log(formik.values);

    const registerHandler = async (values) => {
        setLoading(true);
        try {
            const allValues = {
                ...values,
                date_of_registration: formatDate(formik.values.date_of_registration),
            };
            await userRequest.put(`/schools/${school._id}`, {
                //allValues,
                school_name: allValues.school_name,
                phone_number: allValues.phone_number,
                bvn: allValues.bvn,
                date_of_registration: allValues.date_of_registration,
                registration_type: allValues.registration_type,
                industry: allValues.industry,                
                description: allValues.description,
                
            });

            setSuccess("Business Information added");
            setTimeout(() => {
                setSuccess("");
                setRefresh("information");
                //navigate("/auth/kyc");
            }, 2000);
        } catch (error) {
            console.log(error);
            setError("Something went wrong, try again in a few minutes!");
            setTimeout(() => {
                setError("");
            }, 5000);
        } finally {
            setLoading(false);
        }
    };
    
    return (
        <div>
            <div className="mt-2 max-w-xl mx-auto divide-y">
                <div className="py-2">
                    <h3 className="text-2xl font-bold dark:text-gray-200">Tell us more about your business</h3>
                    <p>
                        As a regulated financial services company, we would need to verify your identification and business registration information.
                    </p>
                    <hr />
                </div>
            </div>
            {info && info.school_name && info.phone_number && info.bvn && info.date_of_registration && info.industry && info.registration_type && info.description ? (
            <div className="mt-2 max-w-xl mx-auto divide-y p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-4 dark:bg-gray-800 dark:border-gray-700">
                <div className="flow-root">
                    <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                    <p className="text-base font-semibold text-gray-900 truncate dark:text-white">
                                        {info.school_name}
                                    </p>
                                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                        Business Name
                                    </p>
                                </div>
                                <div className="flex-1 min-w-0 items-center">
                                    <p className="text-base font-semibold text-gray-900 truncate dark:text-white">
                                        {info.phone_number}
                                    </p>
                                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                        Business Phone
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                    <p className="text-base font-semibold text-gray-900 truncate dark:text-white">
                                        {info.bvn}
                                    </p>
                                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                        Business BVN
                                    </p>
                                </div>
                                <div className="flex-1 min-w-0 items-center">
                                    <p className="text-base font-semibold text-gray-900 truncate dark:text-white">
                                        {info.date_of_registration}
                                    </p>
                                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                        Date of Registration
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                    <p className="text-base font-semibold text-gray-900 truncate dark:text-white">
                                        {info.industry}
                                    </p>
                                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                        Business Category
                                    </p>
                                </div>
                                <div className="flex-1 min-w-0 items-center">
                                    <p className="text-base font-semibold text-gray-900 truncate dark:text-white">
                                        {info.registration_type}
                                    </p>
                                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                        Registration Type
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                        About your School
                                    </p>
                                    <p className="text-base font-semibold text-gray-900 truncate dark:text-white">
                                        {info.description}
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            ) : null }
            <div className="py-2 items-right">
                <div className="max-w-xl mx-auto mt-3 divide-y">
                    {success &&
                        <div className="alert alert-success d-flex align-items-center" role="alert">
                            <i className="fal fa-check pr-10"></i>
                            <div>
                                {success}
                            </div>
                        </div>
                    }
                    {error && 
                        <div className="alert alert-warning d-flex align-items-center" role="alert">
                            <i className="fal fa-lock pr-10"></i>
                            <div>
                                {error}
                            </div>
                        </div>
                    }
                    <form onSubmit={formik.handleSubmit}>
                        <div className="grid grid-cols-1 gap-6">
                            <div>
                                <p className="text-gray-700 dark:text-gray-200">School Information</p>
                                <div className="lg:grid lg:grid-cols-2 lg:gap-x-2">
                                    <div className="mb-2">
                                        <span className="text-gray-700 dark:text-gray-200">Business Name</span>
                                        <input type="text" id="school_name" name="school_name" placeholder="Legal Business Name" defaultValue={info ? info.school_name : formik.handleChange() ? formik.values.school_name : ""} onBlur={formik.handleBlur} onChange={formik.handleChange} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                                        {formik.errors.school_name && formik.touched.school_name &&
                                            <p className="mt-2 text-sm text-red-600 dark:text-red-500">{formik.errors.school_name}</p>}
                                    </div>
                                    <div className="mb-2">
                                        <span className="text-gray-700 dark:text-gray-200">Business Phone Number</span>
                                        <input type="text" id="phone_number" name="phone_number" placeholder="Business Phone Number" value={formik.values.phone_number} onBlur={formik.handleBlur} onChange={formik.handleChange} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                                        {formik.errors.phone_number && formik.touched.phone_number &&
                                            <p className="mt-2 text-sm text-red-600 dark:text-red-500">{formik.errors.phone_number}</p>}
                                    </div>
                                </div>
                            </div>
                            <label className="block">
                                <span className="text-gray-700 dark:text-gray-200">Business BVN <small>Business officier/signatory</small></span>
                                <input type="text" placeholder='BVN' id="bvn" value={formik.values.bvn} onBlur={formik.handleBlur} onChange={formik.handleChange} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                                {formik.errors.bvn && formik.touched.bvn &&
                                    <p className="mt-2 text-sm text-red-600 dark:text-red-500">{formik.errors.bvn}</p>}
                            </label>
                            <div>
                                <p className="text-gray-700 dark:text-gray-200 mb-2">Business Category and Registration Type</p>
                                <div className="lg:grid lg:grid-cols-2 lg:gap-x-2">
                                    <div className="mb-2">
                                        <select id="industry" value={formik.values.industry} onBlur={formik.handleBlur} onChange={formik.handleChange} className="bg-gray-100 border-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                            <option value="">Business Category</option>
                                            <option value="Education-NurserySchools">Education - Nursery Schools</option>
                                            <option value="Education-PrimarySchools">Education - Primary Schools</option>
                                            <option value="Education-SecondarySchools">Education - Secondary Schools</option>
                                            <option value="Education-TertiaryInstitutions">Education - Tertiary Institutions</option>
                                            <option value="Education-OtherEducationalServices">Education - Other EducationalServices</option>
                                        </select>
                                        {formik.errors.industry && formik.touched.industry &&
                                            <p className="mt-2 text-sm text-red-600 dark:text-red-500"> {formik.errors.industry}</p>}
                                    </div>
                                    <div className="">
                                        <select id="registration_type" value={formik.values.registration_type} onBlur={formik.handleBlur} onChange={formik.handleChange} className="bg-gray-100 border-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                                            <option value="">Business Registration Type</option>
                                            <option value="Private_Incorporated">Private Incorporated</option>
                                            <option value="Incorporated_Trustees">Incorporated Trustees</option>
                                            <option value="Business_Name">Business Name</option>
                                            <option value="Free_Zone">Free Zone</option>
                                            <option value="Cooperative_Society">Cooperative Society</option>
                                            <option value="Public_Incorporated">Public Incorporated</option>
                                        </select>
                                        {formik.errors.registration_type && formik.touched.registration_type &&
                                            <p className="mt-2 text-sm text-red-600 dark:text-red-500"> {formik.errors.registration_type}</p>}
                                    </div>
                                </div>
                            </div>
                            
                            <label className="block">
                                <span className="text-gray-700 dark:text-gray-200">Date of Registration</span>
                                <input type="date" id="date_of_registration" value={formik.values.date_of_registration} onBlur={formik.handleBlur} onChange={formik.handleChange} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                                {formik.errors.date_of_registration && formik.touched.date_of_registration &&
                                    <p className="mt-2 text-sm text-red-600 dark:text-red-500"> {formik.errors.date_of_registration}</p>}
                            </label>
                            <label className="block">
                                <p className="text-gray-700 dark:text-gray-200">About your School</p>
                                <textarea id="description" value={formik.values.description} onBlur={formik.handleBlur} onChange={formik.handleChange} placeholder="Business Description" rows="4" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-100 rounded-lg border border-gray-100 focus:ring-blue-500 focus:border-blue-500"></textarea>
                                {formik.errors.description && formik.touched.description &&
                                    <p className="mt-2 text-sm text-red-600 dark:text-red-500"> {formik.errors.description}</p>}
                            </label>
                            <div className="px-4 py-3 text-right sm:px-6">
                                {loading ?
                                    (
                                        <ButtonLoading styles="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-400" />
                                    ) : (
                                        <button type="submit" className="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Save Details</button>
                                    )
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Information
