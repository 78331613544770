import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { userRequest } from '../utils/requestMethods';
import { Header, BackButton } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { ImSpinner } from "react-icons/im";
import { formatter } from '../utils/currencyUtils';

const TransactionDetails = ({ history }) => {
    const { school } = useStateContext();
    const schoolId = school?._id
    const location = useLocation();
    const transactionId= location.pathname.split('/')[2];
    const transactionId1 = location.pathname.split('/')[3];
    //console.log(transactionId);
    const [transaction, setTransaction] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchTransaction = async () => {
          try {
            setLoading(true);
            const { data } = await userRequest.get(`/transactions/school/${schoolId}/${transactionId}`);
            console.log(data);
            setTransaction(data);
          } catch (error) {
            console.log(error);
          } finally {
            setLoading(false);
          }
        };
        if (schoolId && transactionId) { // Ensure IDs are available
            fetchTransaction();
        }
    }, [schoolId, transactionId]);
    
  return (
    <div className="m-4 md:m-10 mt-24 p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
        <BackButton history={history} />
        <Header category="Payouts" title="Transaction Details" />
            {loading ? (
                <div className="py-4 overflow-scroll md:overflow-hidden flex justify-center items-center h-full">
                    <div className="max-w-xl mx-auto mt-3 divide-y">
                        <div className="grid grid-cols-1 gap-6">
                            <p className="block text-center">
                                <ImSpinner className="animate-spin text-4xl text-gray-400" />
                            </p>
                        </div>
                    </div>
                </div>
            ) : (
            <div class="w-full p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                <div class="flex items-center justify-between mt-4 mb-4 p-2 rounded-lg" style={{ backgroundColor: "#4543B5" }}>
                    <h5 class="text-xl font-bold leading-none text-white">Transaction Details</h5>
                </div>
                <div class="flow-root text-center mb-5">
                    <ul class="divide-y divide-gray-200 dark:divide-gray-700">
                        <li class="py-3 sm:py-4">
                            <div class="flex items-center space-x-4">
                                <div class="flex-shrink-0">
                                    <p class="text-md font-semibold text-gray-900 truncate dark:text-white">
                                        Transaction ID:
                                    </p>
                                </div>
                                <div class="flex-1 min-w-0"></div>
                                <div class="inline-flex items-center">
                                    <p class="text-sm font-semibold text-gray-900 truncate dark:text-white">
                                        {transaction?._id}
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="py-3 sm:py-4">
                            <div class="flex items-center space-x-4">
                                <div class="flex-shrink-0">
                                    <p class="text-md font-semibold text-gray-900 truncate dark:text-white">
                                        Transaction Type:
                                    </p>
                                </div>
                                <div class="flex-1 min-w-0"></div>
                                <div class="inline-flex items-center">
                                    <p class="text-sm font-semibold text-gray-900 capitalize truncate dark:text-white">
                                        {transaction?.type?.replace('_', ' ')}
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="py-3 sm:py-4">
                            <div class="flex items-center space-x-4">
                                <div class="flex-shrink-0">
                                    <p class="text-md font-semibold text-gray-900 truncate dark:text-white">
                                        Amount Paid in This Transaction:
                                    </p>
                                </div>
                                <div class="flex-1 min-w-0"></div>
                                <div class="inline-flex items-center">
                                    <p class="text-sm font-semibold text-gray-900 truncate dark:text-white">
                                        {formatter.format(transaction?.amount) || 0}
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="py-3 sm:py-4">
                            <div class="flex items-center space-x-4">
                                <div class="flex-shrink-0">
                                    <p class="text-md font-semibold text-gray-900 truncate dark:text-white">
                                        Transaction Fee:
                                    </p>
                                </div>
                                <div class="flex-1 min-w-0"></div>
                                <div class="inline-flex items-center">
                                    <p class="text-sm font-semibold text-gray-900 truncate dark:text-white">
                                        {formatter.format(transaction?.transaction_fee) || 0}
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="py-3 sm:py-4">
                            <div class="flex items-center space-x-4">
                                <div class="flex-shrink-0">
                                    <p class="text-md font-semibold text-gray-900 truncate dark:text-white">
                                        Total Paid:
                                    </p>
                                </div>
                                <div class="flex-1 min-w-0"></div>
                                <div class="inline-flex items-center">
                                    <p class="text-sm font-semibold text-gray-900 truncate dark:text-white">
                                        {formatter.format(transaction?.final_amount) || 0}
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="py-3 sm:py-4">
                            <div class="flex items-center space-x-4">
                                <div class="flex-shrink-0">
                                    <p class="text-md font-semibold text-gray-900 truncate dark:text-white">
                                        Destination Account Name:
                                    </p>
                                </div>
                                <div class="flex-1 min-w-0"></div>
                                <div class="inline-flex items-center">
                                    <p class="text-sm font-semibold text-gray-900 truncate dark:text-white">
                                        {transaction?.metadata?.destination?.accountName}
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="py-3 sm:py-4">
                            <div class="flex items-center space-x-4">
                                <div class="flex-shrink-0">
                                    <p class="text-md font-semibold text-gray-900 truncate dark:text-white">
                                        Destination Account Details:
                                    </p>
                                </div>
                                <div class="flex-1 min-w-0"></div>
                                <div class="inline-flex items-center">
                                    <p class="text-sm font-semibold text-gray-900 truncate dark:text-white">
                                        {transaction?.metadata?.destination?.accountNumber} - {transaction?.metadata?.destination?.bank?.name}
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="py-3 sm:py-4">
                            <div class="flex items-center space-x-4">
                                <div class="flex-shrink-0">
                                    <p class="text-md font-semibold text-gray-900 truncate dark:text-white">
                                        Purpose:
                                    </p>
                                </div>
                                <div class="flex-1 min-w-0"></div>
                                <div class="inline-flex items-center">
                                    <p class="text-sm font-semibold text-gray-900 truncate dark:text-white">
                                        {transaction?.metadata?.reason || "Payout"}
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="py-3 sm:py-4">
                            <div class="flex items-center space-x-4">
                                <div class="flex-shrink-0">
                                    <p class="text-md font-semibold text-gray-900 truncate dark:text-white">
                                        Status:
                                    </p>
                                </div>
                                <div class="flex-1 min-w-0"></div>
                                <div class="inline-flex items-center">
                                    <p class="text-sm font-semibold text-gray-900 truncate dark:text-white">
                                        <button type="button" style={{ background: 'rgb(139, 231, 139)' }} className="text-white py-1 px-2 capitalize rounded-2xl text-md font-bold">{transaction?.status}</button>
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="py-3 sm:py-4">
                            <div class="flex items-center space-x-4">
                                <div class="flex-shrink-0">
                                    <p class="text-md font-semibold text-gray-900 truncate dark:text-white">
                                        Date:
                                    </p>
                                </div>
                                <div class="flex-1 min-w-0"></div>
                                <div class="inline-flex items-center">
                                    <p class="text-sm font-semibold text-gray-900 truncate dark:text-white">
                                        {(new Date(transaction?.updatedAt)).toLocaleString('en-NG', {hour12: true})}
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            )}
        </div>
  )
}

export default TransactionDetails