import React, { useState } from 'react';
import { useFormik } from "formik";
import { recoverSchema } from '../../utils/formSchema';
import { publicRequest } from '../../utils/requestMethods';
import { ButtonLoading } from '../../components';
import './css/default.css'
import './css/style.css'
import logo from "../../data/logo/logo.png";
import circle from './img/circle.png';
import dot from './img/dot.png';
import signup from './img/sign-up.png';
import zigzag from './img/zigzag.png';

const Reset = ({ history }) => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
    } = useFormik({
        initialValues: {
           code: "",
           password: "",
           confirmPassword: "",
        },
        validationSchema: recoverSchema,
        onSubmit: (values) => {
           // Handle form submission here
           recoverHandler(values);
           //console.log(values);
        },
    });
    
    const recoverHandler = async (values) => {
        setLoading(true);
        //console.log(values.code);

        try {
            await publicRequest.post(`auth/reset-password?tkn=${values.code}`, values);
            
            setSuccess("New password set successfully");
            setTimeout(() => {
                setSuccess("");
                history.push("/signin");
            }, 5000);
        } catch (error) {
            //console.log(error.response.data);
            setError(error.response.data);
            setTimeout(() => {
                setError("");
            }, 5000);
            setLoading(false);
        }
        setLoading(false);
    };

    return (
        <main>
            <section className="signup__area po-rel-z1 p-t-40 pb-100">
                <div className="sign__shape">
                    <img className="circle" src={circle} alt="" />
                    <img className="zigzag" src={zigzag} alt="" />
                    <img className="dot" src={dot} alt="" />
                    <img className="bg" src={signup} alt="" />
                </div>
                <div className="row">
                    <div className="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2">
                        <div className="section__title-wrapper text-center m-b-40">
                            <img src={logo} alt="logo" className="mx-auto" width="250" />
                        </div>
                    </div>
                </div>

                <div className="max-w-xl mx-auto mt-3 divide-y p-3">
                    {error &&
                        <div className="alert alert-warning d-flex align-items-center" role="alert">
                            <i className="fal fa-lock pr-10"></i>
                            <div>
                                {error}
                            </div>
                        </div>
                    }
                    {success &&
                    <div className="alert alert-success d-flex align-items-center" role="alert">
                        <i className="fal fa-check pr-10"></i>
                        <div>
                            {success}
                        </div>
                    </div>
                    }
                    <div className="sign__wrapper bg-white mx-auto">
                        <form onSubmit={handleSubmit}>
                            <div className="sign__form px-2">
                                <h3 className="text-2xl font-bold text-center">Set Password</h3>
                                <p className="mb-25 text-center">Provide the reset code and your new password.</p>
                                <div className="sign__input-wrapper mb-25">
                                    <h5>Reset Token</h5>
                                    <div className="col-md-4 sign__input">
                                        <input type="text" id="code" placeholder="Reset Token" value={values.code} onBlur={handleBlur} onChange={handleChange} required />
                                        <i className="fal fa-envelope"></i>
                                        {errors.code && touched.code &&
                                            <p className="mt-2 text-sm text-red-600 dark:text-red-500"><span className="font-medium">Oops!</span> {errors.code}</p>}
                                    </div>
                                </div>
                                <div className="sign__input-wrapper mb-25">
                                    <h5>New Password</h5>
                                    <div className="col-md-4 sign__input">
                                        <input type="password" id="password" placeholder="Password" value={values.password} onBlur={handleBlur} onChange={handleChange} required/>
                                        <i className="fal fa-envelope"></i>
                                        {errors.password && touched.password &&
                                            <p className="mt-2 text-sm text-red-600 dark:text-red-500"><span className="font-medium">Oops!</span> {errors.password}</p>}
                                    </div>
                                </div>
                                <div className="sign__input-wrapper mb-25">
                                    <h5>Confirm Password</h5>
                                    <div className="col-md-4 sign__input">
                                        <input type="password" id="confirmPassword" placeholder="Confirm Password" value={values.confirmPassword} onBlur={handleBlur} onChange={handleChange} required/>
                                        <i className="fal fa-envelope"></i>
                                        {errors.confirmPassword && touched.confirmPassword &&
                                            <p className="mt-2 text-sm text-red-600 dark:text-red-500"><span className="font-medium">Oops!</span> {errors.confirmPassword}</p>}
                                    </div>
                                </div>

                                {loading ?
                                    (
                                        <ButtonLoading styles="e-btn w-100 auth-btn" />
                                    ) : (
                                        <button type="submit" className="e-btn w-100 auth-btn"> Verify</button>
                                    )
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </main>
    );
};
export default Reset;