import React, { useState, useEffect } from 'react';
import { userRequest } from '../utils/requestMethods';
import { Header, ButtonLoading, BackButton } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { ImSpinner } from "react-icons/im";

const PaymentCompliance = ({ history }) => {
    const { user, school } = useStateContext();
    //const schoolId = school?._id;

    const [banks, setBanks] = useState([]);
    const [bankDetails, setBankDetails] = useState({});
    //const [resolve, setResolve] = useState({});
    const [bankCode, setBankCode] = useState("");
    const [accountName, setAccountName] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    //console.log(accountName + " school");

    /*fetch banks detais
    useEffect(() => {
        const fetchDetails = async () => {
            try {
                setPageLoading(true);
                const {data} = await userRequest.get(`banks/${schoolId}`);
                //console.log(res.data);
                setBankDetails(data);
                setPageLoading(false);
            } catch (error) {
                console.log(error);
            }
        };
        fetchDetails();
    }, [accessCode, schoolId]);*/

    useEffect(() => {
        setPageLoading(true);
        // Update pay out account detail if available
        if (school) {
            setBankDetails(school.counter_party);
            console.log("ran!")
        }
        setPageLoading(false);
    }, [school]);
    
    useEffect(() => {
        const fetchBanks = async () => {
          try {
            setLoading(true);
            const { data } = await userRequest.get(`banks/anchor-banks`);
            setBanks(data);
            setLoading(false);
          } catch (error) {
            console.log(error);
          }
        };
        fetchBanks();
    }, [user]);
    
    const resolveHandler = async (e) => {
        e.preventDefault();
        setBankCode(e.target.value);
        setLoading(true);
    
        try {
            const { data } = await userRequest.post(`/banks/anchor-verify-account`, {
                bankIdOrBankCode: e.target.value,
                accountNumber
            });
            //console.log(data);
            setAccountName(data.attributes.accountName);
        } catch (error) {
            console.log(error);
            setError("Error, unable to resolve account!");
            setTimeout(() => {
                setError("");
            }, 5000);
        } finally {
            setLoading(false);
        }
    };
    
    const saveHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!accountName || !accountNumber || !bankCode) {
            setError("Please Fill all the Feilds");
            setTimeout(() => {
                setError("");
            }, 5000);
            setLoading(false);
            return;
        }
     
        try {
           await userRequest.post(`/schools/${school?._id}/create-counter-party`, {
            accountName: accountName,
            accountNumber: accountNumber,
            bankCode: bankCode
           });
     
           setSuccess("Account Information added");
           setTimeout(() => {
              setSuccess("");
              //navigate("/auth/kyc");
           }, 2000);
        } catch (error) {
           console.log(error);
           setError(error?.response?.data);
           setTimeout(() => {
              setError("");
           }, 5000);
        } finally {
         setLoading(false);
       }
     };

    return (
        <div className="m-2 md:m-10 mt-24 shadow p-2 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl min-h-screen">
            <BackButton history={history} />
            <Header category="Onboarding" title="Payment Information" />
            <div className="mt-2 max-w-full divide-y">
                <div className="py-2">
                    <h3 className="text-1xl font-bold dark:text-gray-200">Withdrawal Bank Account</h3>
                    <hr />
                </div>
            </div>
            {pageLoading ? (
                <div className="py-4 overflow-scroll md:overflow-hidden flex justify-center items-center h-full">
                    <div className="max-w-xl mx-auto mt-3 divide-y">
                        <div className="grid grid-cols-1 gap-6">
                            <p className="block text-center">
                                <ImSpinner className="animate-spin text-4xl text-gray-400" />
                            </p>
                        </div>
                    </div>
                </div>
            ) : bankDetails ? (
            <div className="py-4 overflow-scroll md:overflow-hidden">
                <table className="border-collapse table-auto w-full border border-slate-300 dark:border-slate-500 bg-white dark:bg-slate-800 text-sm rounded-3xl">
                    <thead className="bg-slate-50 dark:bg-slate-700">
                        <tr>
                            <th className="border-b border-slate-300 dark:border-slate-600 font-semibold p-6 text-slate-900 dark:text-slate-200 text-left">Bank Name</th>
                            <th className="border-b border-slate-300 dark:border-slate-600 font-semibold p-6 text-slate-900 dark:text-slate-200 text-left">Account Name</th>
                            <th className="border-b border-slate-300 dark:border-slate-600 font-semibold p-6 text-slate-900 dark:text-slate-200 text-left">Bank Account Number</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="border-b border-slate-300 dark:border-slate-700 p-6 text-slate-500 dark:text-slate-400">{bankDetails?.bank_name}</td>
                            <td className="border-b border-slate-300 dark:border-slate-700 p-6 text-slate-500 dark:text-slate-400">{bankDetails?.account_name}</td>
                            <td className="border-b border-slate-300 dark:border-slate-700 p-6 text-slate-500 dark:text-slate-400">{bankDetails?.account_number}</td>                        
                        </tr>
                    </tbody>
                </table>
            </div>
            )   :   (
            <>
            <div className="py-2 items-right">
                <div className="max-w-xl mx-auto mt-3 divide-y">
                    {success &&
                        <div className="alert alert-success d-flex align-items-center" role="alert">
                            <i className="fal fa-check pr-10"></i>
                            <div>
                                {success}
                            </div>
                        </div>
                    }
                    {error && 
                        <div className="alert alert-warning d-flex align-items-center" role="alert">
                            <i className="fal fa-lock pr-10"></i>
                            <div>
                                {error}
                            </div>
                        </div>
                    }
                    <form onSubmit={saveHandler}>
                        <div className="grid grid-cols-1 gap-6">
                            <label className="block">
                                <span className="text-gray-700 dark:text-gray-200">Bank Account Number <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">{accountName}</span></span>
                                <input type="text" id="accountNumber" onChange={(e) => setAccountNumber(e.target.value)} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                            </label>
                            <label className="block">
                                <span className="text-gray-700 dark:text-gray-200">Bank Name</span>
                                <select id="bankCode" onChange={resolveHandler} className="bg-gray-100 border-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required="">
                                    <option value="">Select</option>
                                    {banks?.map((bank) => (
                                        <option key={bank.id} value={bank.attributes.nipCode}>
                                            {bank.attributes.name}
                                        </option>
                                    ))}
                                </select>
                            </label>
                            <div className="px-4 py-3 text-right sm:px-6">
                                {loading ?
                                    (
                                        <ButtonLoading styles="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-400" />
                                    ) : (
                                        <button type="submit" className="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Save Details</button>
                                    )
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </>
            )}
            <hr />
            {/*<div className="px-4 py-3 sm:px-6 mb-2">
                <button className="float-right inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-400">Next</button>
                <Link to="/compliance/business">
                    <button className="float-left inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Back</button>
                </Link>
            </div>*/}
        </div>
    );
};
export default PaymentCompliance;