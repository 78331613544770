export const exportToCSV = (data, columns, filename = 'export.csv') => {
  const csvRows = [];

  // Filter out columns that shouldn't be exported (e.g., button columns)
  const exportableColumns = columns.filter(col => col.name); // Only include columns with a name

  // Extract headers
  const headers = exportableColumns.map(col => col.name);
  csvRows.push(headers.join(','));

  // Extract data rows
  data.forEach(row => {
    const values = exportableColumns.map(col => {
      // Use exportCell for exporting if available, otherwise use cell
      const cellValue = typeof col.exportCell === 'function'
        ? col.exportCell(row)
        : (typeof col.cell === 'function' ? col.cell(row) : '');

      // Handle null/undefined values
      return typeof cellValue === 'undefined' || cellValue === null ? '' : `"${cellValue}"`;
    });
    csvRows.push(values.join(','));
  });

  // Create a blob and trigger download
  const csvContent = csvRows.join('\n');
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', filename);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
