import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { userRequest } from '../utils/requestMethods';
import { BackButton, Header, PaymentsTable } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
//import { displaySchoolTerm, generateSchoolSessions } from '../utils/schoolYearUtils';
//import { ImSpinner } from 'react-icons/im';
//import DataTable from 'react-data-table-component';
//import { FiArrowRight } from 'react-icons/fi';

const TransactionPerClass = ({history}) => {
  //const schoolSessions = generateSchoolSessions(2022, 20);
  const { school } = useStateContext();
  const [transactions, setTransactions] = useState();
  //const [classes, setClasses] = useState();
  //const [paymentSummary, setPaymentSummary] = useState();
  const [loading, setLoading] = useState(false);
  const schoolId = school?._id;
  const location = useLocation();
  const addonId = location.pathname.split('/')[3];
  const nameOfClass = location.pathname.split('/')[4];
  //console.log(accessCode);
  //console.log(userId);

  useEffect(() => {
    if (addonId && schoolId) {
      fetchClassAddon();
    }
  }, [addonId, schoolId]);

  /*const fetchData = async () => {
    setLoading(true);
    try {
      const [classesPaymentResponse, paymentSummaryResponse] = await Promise.all([
        userRequest.get(`/payments/school/${schoolId}?class_id=${addonId}&term=${school.term}&session=${school.session}&limit=200&sort_by=date&order=des`),
        userRequest.get(`schools/${schoolId}/payment-summary?class_id=${addonId}&term=${school.term}&session=${school.session}`),
      ]);

      setTransactions(classesPaymentResponse.data.payments);
      setPaymentSummary(paymentSummaryResponse.data);
      console.log(paymentSummaryResponse.data);
    } catch (error) {
      console.error("Failed to fetch data:", error);
    } finally {
      setLoading(false);
    }
  };*/

  const fetchClassAddon = async () => {
    try {
      setLoading(true);
      /*const { data } = await userRequest.get(`/class-addon/${schoolId}`);

      console.log(data);
      const addOnsWithClassName = data.flatMap(classItem =>
        classItem.add_on.map(addOn => ({
          ...addOn,
          class_name: classItem.class_name
        }))
      );
      setClasses(addOnsWithClassName);*/
      const { data } = await userRequest.get(`/payments/school/${schoolId}?add_on_id=${addonId}&term=${school.term}&session=${school.session}&limit=200&sort_by=date&order=des`);

      console.log(data.payments);
      setTransactions(data.payments);
      setLoading(false);
    } catch (error) {
        console.log(error);
    }
  };


  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 dark:bg-secondary-dark-bg bg-white rounded-3xl min-h-screen">
      <div className="flex flex-wrap justify-between mb-4">
        <BackButton history={history} />
      </div>
        <Header category="Payments" title={nameOfClass} />
        {/*<PaymentSummary paymentSummary={paymentSummary} />*/}
        <PaymentsTable transactions={transactions} loading={loading} nameOfClass={nameOfClass} />
    </div>
  );
};
export default TransactionPerClass;
