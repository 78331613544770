import React, { useEffect, useState, useCallback } from 'react'
import { debounce } from 'lodash';
import ButtonLoading from './ButtonLoading'
import { userRequest } from '../utils/requestMethods';
import SendOTP from './SendOTP';

const TransferModal = ({ closeModal, school }) => {
    const [banks, setBanks] = useState([]);
    const [bankDetails, setBankDetails] = useState({});
    const [amount, setAmount] = useState("");
    const [counterParty, setCounterParty] = useState("");
    const [bankCode, setBankCode] = useState("");
    const [accountName, setAccountName] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [otp, setOtp] = useState("");
    const [reason, setReason] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);

    // Clear messages function
    const clearMessages = (setter, delay = 5000) => {
        setTimeout(() => setter(""), delay);
    };

    // Load school counter-party details
    useEffect(() => {
        if (school) {
            setPageLoading(true);
            setBankDetails(school.counter_party);
            //console.log(school.counter_parties);
            setPageLoading(false);
        }
    }, [school]);

    // Fetch available banks
    useEffect(() => {
        const fetchBanks = async () => {
            setLoading(true);
            try {
                const { data } = await userRequest.get('banks/anchor-banks');
                setBanks(data);
            } catch (err) {
                console.error(err);
            } finally {
                setLoading(false);
            }
        };
        fetchBanks();
    }, []);

    // Resolve bank account handler
    const resolveHandler = useCallback(debounce(async (e) => {
        const selectedBankCode = e.target.value;
        setBankCode(selectedBankCode);
    
        if (!accountNumber) {
            setError("Please enter a valid account number");
            clearMessages(setError);
            return;
        }
    
        setLoading(true);
    
        try {
            // Resolve account name using bank code and account number
            const { data: resolveData } = await userRequest.post('/banks/anchor-verify-account', {
                bankIdOrBankCode: selectedBankCode,
                accountNumber,
            });
    
            const resolvedAccountName = resolveData.attributes?.accountName;
    
            if (resolvedAccountName) {
                setAccountName(resolvedAccountName);
    
                // Optionally: Save the counter-party details (could be separate or combined)
                const { data: counterPartyData } = await userRequest.post(`/schools/${school?._id}/create-counter-party`, {
                    accountName: resolvedAccountName,
                    accountNumber,
                    bankCode: selectedBankCode,
                });
                console.log(counterPartyData.id);
                setCounterParty(counterPartyData.id);
            } else {
                setError("Error: Unable to resolve account.");
            }
        } catch (err) {
            const errorMessage = err?.response?.data?.message || "Error: Unable to resolve account.";
            setError(errorMessage);
        } finally {
            clearMessages(setError);
            setLoading(false);
        }
    }, 500), [accountNumber, school?._id]); // Debounce delay set to 500ms to prevent rapid calls

    // Save account details handler
    const saveHandler = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!accountName || !accountNumber || !bankCode || !amount) {
            setError("Please fill all the fields.");
            clearMessages(setError);
            setLoading(false);
            return;
        }

        try {
            await userRequest.post(`/transactions/initiate-transfer/${school?._id}`, {
                amount,
                reason,
                counterPartyId: counterParty,
                otp
            });
            setSuccess("Success: Money on it's way!.");
            clearMessages(setSuccess, 5000);
        } catch (err) {
            setError(err?.response?.data?.message || "An error occurred.");
            clearMessages(setError);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-md" onClick={closeModal}></div>
            <div className="rounded-lg overflow-y-auto max-h-[90vh] z-50">
                <div className="w-full max-w-md max-h-full">
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Make a Transfer
                            </h3>
                            <button onClick={closeModal} type="button" className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-4 md:p-5">
                            <div className="max-w-xl mx-auto mt-3 divide-y">
                                {success && (
                                    <div className="alert alert-success" role="alert">
                                        {success}
                                    </div>
                                )}
                                {error && (
                                    <div className="alert alert-warning" role="alert">
                                        {error}
                                    </div>
                                )}
                                <form onSubmit={saveHandler}>
                                    <div className="grid grid-cols-1 gap-6">
                                        <SendOTP school={school} />
                                        <label className="block">
                                            <span className="text-gray-700 dark:text-gray-200">Amount to Transfer</span>
                                            <input
                                                type="number"
                                                onChange={(e) => setAmount(e.target.value)}
                                                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                                            />
                                        </label>
                                        <label className="block">
                                            <span className="text-gray-700 dark:text-gray-200">
                                                Bank Account Number <span className="bg-blue-100 text-blue-800 text-xs font-medium rounded dark:bg-blue-900 dark:text-blue-300">{accountName}</span>
                                            </span>
                                            <input
                                                type="number"
                                                onChange={(e) => setAccountNumber(e.target.value)}
                                                className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                                            />
                                        </label>
                                        <label className="block">
                                            <span className="text-gray-700 dark:text-gray-200">Bank Name</span>
                                            <select
                                                id="bankCode"
                                                onChange={resolveHandler}
                                                className="bg-gray-100 border-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                            >
                                                <option value="">Select</option>
                                                {banks?.map((bank) => (
                                                    <option key={bank.id} value={bank.attributes.nipCode}>
                                                        {bank.attributes.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </label>
                                        <label className="block">
                                            <span className="text-gray-700 dark:text-gray-200">Generated OTP</span>
                                            <input type="text" id="OTP" onChange={(e) => setOtp(e.target.value)} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder='XXXXXX' />
                                        </label>
                                        <label className="block">
                                            <span className="text-gray-700 dark:text-gray-200">Purpose</span>
                                            <input type="text" id="OTP" onChange={(e) => setReason(e.target.value)} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder='Food Vendor' />
                                        </label>
                                        <div className="py-3 text-right">
                                            {loading ? (
                                                <ButtonLoading styles="inline-flex w-full justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-400" />
                                            ) : (
                                                <button type="submit" className="inline-flex w-full justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700">
                                                    Send Funds
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TransferModal;
