import React, { useState, useEffect } from 'react';
import { userRequest } from '../../utils/requestMethods';
import { Header, ButtonLoading, SuccessModal } from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';
import { ImSpinner } from "react-icons/im";

const DocumentUpload = ({ schoolId, director }) => {
    const [idType, setIdType] = useState("");
    const [file, setFile] = useState(null);
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!idType || !file) {
            setError('Please select a file.');
            setTimeout(() => {
                setError("");
            }, 2000);
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('directorId', director._id);
        formData.append('idType', idType);

    try {
        setSaving(true);
        const { data } = await userRequest.post(`/schools/${schoolId}/upload-director-id`, formData, {
            headers: {
            'Content-Type': 'multipart/form-data',
            },
        });
        console.log(data);
        setSuccess('Document uploaded successfully!');
        setTimeout(() => {
            setSuccess("");
        }, 2000);
    } catch (error) {
        console.error('Error uploading document:', error);
        setError('Error uploading document, try again later');
        setTimeout(() => {
            setError("");
        }, 2000);
    } finally {
        setSaving(false);
    }
  };

  return (
    <div className="block mb-6">
        {error && (
            <div className="alert alert-warning flex items-center" style={{ paddingTop: "5px", paddingBottom: "5px"}} role="alert">
                <i className="fal fa-lock pr-2"></i>
                <div>{error}</div>
            </div>
        )}
        {success && (
            <div className="alert alert-success flex items-center" style={{ paddingTop: "5px", paddingBottom: "5px"}} role="alert">
                <i className="fal fa-check pr-2"></i>
                <div>{success}</div>
            </div>
        )}
        <span className="text-gray-700 font-bold dark:text-gray-200">Document for {director.first_name+ " " +director.last_name} (only images)</span>
        <form onSubmit={handleSubmit}>
            <div className="lg:grid lg:grid-cols-3 lg:gap-x-2">
                <div className="lg:col-span-1 mb-2">
                    <select onChange={(e) => setIdType(e.target.value)} value={idType} className="block w-full mt-1 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0">
                        <option value="">Document Type</option>
                        <option value="national_id">National ID (NIN)</option>
                        <option value="international_passport">International Passport</option>
                        <option value="drivers_license">Driver's License</option>
                    </select>
                </div>
                <div className="lg:col-span-1 mb-2">
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                    />
                </div>
                <div className="lg:col-span-1 mb-2">
                    {saving ? (
                        <ButtonLoading styles="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-400" />
                    ) : (
                        <button type="submit" className="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Save</button>
                    )}
                </div>
            </div>
        </form>
    </div>
  );
};

const DirectorsIdentity = ({ history }) => {
    const { school } = useStateContext();
    //const schoolId = school?._id;
    const [directors, setDirectors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = () => {
      setIsModalOpen(!isModalOpen);
    };

    useEffect(() => {
        const fetchTransactions = async () => {
          try {
            setLoading(true);
            const { data } = await userRequest.get(`schools/${school._id}`);
            //console.log(data.officials);
            setUserInfo(data);
            setDirectors(data.officials);
            setLoading(false);
          } catch (error) {
            console.log(error);
          }
        };
        fetchTransactions();
    }, [school]);

    const goBack = () => {
        // Go back to the previous page
        history.goBack();
    };

  return (
    <div className="m-2 md:m-10 mt-24 shadow p-2 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
        <Header category="Onboarding" title="Compliance Documents" />
        <div className="py-2">
            <button onClick={goBack} className="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-700"><i className="fad fa-arrow-left mt-1 mr-2"></i> Business Documents</button>
            <button type="button" onClick={toggleModal} className="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-700 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 float-right">
                Done
            </button>
        </div>
        <div className="mt-2 max-w-full divide-y">
            <div className="py-2">
                <h3 className="text-1xl font-bold dark:text-gray-200">
                    Kindly provide the directors identification documents below.
                </h3>
                <hr />
            </div>
        </div>
        {loading ? (
            <div className="py-4 overflow-scroll md:overflow-hidden flex justify-center items-center h-full">
                <div className="max-w-xl mx-auto mt-3 divide-y">
                    <div className="grid grid-cols-1 gap-6">
                        <p className="block text-center">
                            <ImSpinner className="animate-spin text-4xl text-gray-400" />
                        </p>
                    </div>
                </div>
            </div>
        ) : (
            <div className="py-2 flex items-center justify-center">
                <div className="max-w-3xl mx-auto mt-3 divide-y">
                    <div className="grid grid-cols-1 gap-6">
                        {directors.map((director) => (
                            <DocumentUpload key={director._id} schoolId={school?._id} director={director} />
                        ))}
                    </div>
                </div>
            </div>

        )}
        <hr />
      
        {isModalOpen && 
            <SuccessModal 
            closeModal={toggleModal}
            schoolId={school?._id}
        />}
    </div>
  );
};

export default DirectorsIdentity;
