import React from 'react'
import { formatter } from '../utils/requestMethods'

const PaymentSummary = ({ paymentSummary }) => {
  return (
    <div className="w-full">
        <div className="grid mb-8 rounded-lg shadow-sm md:mb-12 md:grid-cols-4 bg-white">
            <figure className="border flex flex-col items-center justify-center p-8 text-center bg-white">
                <figcaption className="flex items-center justify-center ">
                    <button type="button" className="text-white bg-blue-700 mr-2 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        <i className="fas fa-chart-line"></i>
                    </button>
                    <div className="space-y-0.5 font-medium dark:text-white text-left rtl:text-right ms-3">
                        <div>{formatter.format(paymentSummary?.totalAmount || 0)}</div>
                        <div className="text-sm text-gray-500 dark:text-gray-400">Expected Income</div>
                    </div>
                </figcaption>    
            </figure>
            <figure className="border flex flex-col items-center justify-center p-8 text-center bg-white">
                <figcaption className="flex items-center justify-center ">
                    <button type="button" className="text-white mr-2 bg-green-600 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        <i className="fas fa-chart-line"></i>
                    </button>
                    <div className="space-y-0.5 font-medium dark:text-white text-left rtl:text-right ms-3">
                        <div>{formatter.format(paymentSummary?.totalAmountPaid || 0)}</div>
                        <div className="text-sm text-gray-500 dark:text-gray-400 ">Total Paid</div>
                    </div>
                </figcaption>    
            </figure>
            <figure className="border flex flex-col items-center justify-center p-8 text-center bg-white">
                <figcaption className="flex items-center justify-center ">
                    <button type="button" className="text-white mr-2 bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        <i className="fas fa-chart-line-down"></i>
                    </button>
                    <div className="space-y-0.5 font-medium dark:text-white text-left rtl:text-right ms-3">
                        <div>{formatter.format(paymentSummary?.totalOutstandingAmount || 0)}</div>
                        <div className="text-sm text-gray-500 dark:text-gray-400 ">Current Debt</div>
                    </div>
                </figcaption>    
            </figure>
            <figure className="border flex flex-col items-center justify-center p-8 text-center bg-white">
                <figcaption className="flex items-center justify-center ">
                    <button type="button" className="text-white mr-2 bg-orange-600 hover:bg-orange-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                        <i className="fas fa-chart-line-down"></i>
                    </button>
                    <div className="space-y-0.5 font-medium dark:text-white text-left rtl:text-right ms-3">
                        <div>{formatter.format(paymentSummary?.discountAmount || 0)}</div>
                        <div className="text-sm text-gray-500 dark:text-gray-400 ">Discount</div>
                    </div>
                </figcaption>    
            </figure>
        </div>
    </div>
  )
}

export default PaymentSummary