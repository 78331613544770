import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { ImSpinner } from "react-icons/im";
import { userRequest, formatter } from '../utils/requestMethods';
import { Header, AddTransportModal, CustomPagination } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import useKYCStatusRedirect from '../hooks/useKYCStatusRedirect';

const customStyles = {
  headRow: {
    style: {
      backgroundColor: 'rgb(248, 250, 252)',
    },
  },
};

const Transportation = () => {
  useKYCStatusRedirect("/transportation");
  const { school } = useStateContext();
  const [addons, setAddons] = useState();
  const [addonName, setAddonName] = useState("");
  const [addonPrice, setAddonPrice] = useState("");
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const schoolId = school?._id;
  const schoolCode = school?.code;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  //console.log(addons);
  /*console.log(schoolId);*/

  useEffect(() => {
    const fetchAddons = async () => {
      try {
        setLoading(true);
        const { data } = await userRequest.get(`transport-addon?code=${schoolCode}`);
        //console.log(data);
        setAddons(data.transport_add_on);
        setLoading(false);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchAddons();
  }, [schoolCode, refresh]);

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!addonName || !addonPrice) {
      setError("Please Fill all the Feilds");
      setTimeout(() => {
          setError("");
      }, 5000);
      setLoading(false);
      return;
    }

    try {
      await userRequest.post("/transport-addon",
        {
          schoolId,
          add_on_name: addonName,
          add_on_price: addonPrice,
        });

      //console.log(data);
      //history.push("/classes");
      setRefresh(!refresh);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        setAddonName("");
        setAddonPrice("");
        toggleModal();
      }, 2000);
    } catch (error) {
      console.log(error);
      setError(error.response.data);
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
    }
    setLoading(false);
  };

  const deleteHandler = async (addonId) => {
    //console.log(classId);
    try {
      await userRequest.delete(`/transport-addon/${schoolId}/${addonId}`);
      //console.log(res.data.add_on);
      //setAddons(res.data.add_on)
      setRefresh(!refresh);
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      field: "email",
      name: "Bus Location",
      selector: row => <span className="font-bold">{row.add_on_name}</span>
    },
    {
      field: "price",
      name: "Amount",
      selector: row => formatter.format(row.add_on_price)
    },
    {
      cell: (row) => <button style={{ background: 'rgb(139, 231, 139)' }} className="text-white py-1 px-2 capitalize rounded-2xl text-md font-bold">Active</button>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      cell: (row) => <Link to={`/update-transport/${row._id}`} style={{ color: '#1e4db7', backgroundColor: '#91a6f5' }} className="text-md opacity-0.9 rounded-full p-3 hover:drop-shadow-xl"><FiEdit /></Link>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      cell: (row) => <button onClick={() => deleteHandler(row._id)} style={{ color: 'rgb(228, 106, 118)', backgroundColor: 'rgb(255, 244, 229)' }} className="text-md opacity-0.9 rounded-full p-3 hover:drop-shadow-xl"><FiTrash2 /></button>,
      ignoreRowClick: true,
      button: true,
    },
  ];

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 dark:bg-secondary-dark-bg bg-white rounded-3xl min-h-screen">
        <button type="button" onClick={toggleModal} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-700 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 float-right">
          Add Bus Location
        </button>
      <Header category="Set up" title="School Bus" />
      <DataTable
        data={addons}
        columns={columns}
        getRowId={(row) => row._id}
        pagination
        paginationComponentOptions={{
          rowsPerPageText: 'Rows per page:',
          rangeSeparatorText: 'of',
          selectAllRowsItem: true,
          selectAllRowsItemText: 'All',
        }}
        paginationComponent={CustomPagination}
        paginationRowsPerPageOptions={[10, 20, 30, 50]}
        customStyles={customStyles}
        progressPending={loading}
        progressComponent={
          <ImSpinner className="animate-spin text-4xl text-gray-400" />
        }
      />

      {isModalOpen && 
        <AddTransportModal 
        closeModal={toggleModal} 
        addonName={addonName}
        setAddonName={setAddonName}
        addonPrice={addonPrice}
        setAddonPrice={setAddonPrice}
        error={error}
        success={success}
        loading={loading} 
        submitHandler={submitHandler}
        />
      }
    </div>
  );
};
export default Transportation;
