import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import { userRequest } from '../utils/requestMethods';
import { Header, ButtonLoading } from '../components';
import { useStateContext } from '../contexts/ContextProvider';

const UpdateTransport = ({ history }) => {
  const location = useLocation();
  const addonId = location.pathname.split('/')[2];

  const { school } = useStateContext();
  const schoolId = school?._id;

  //const [addonDetails, setAddonDetails] = useState({});
  const [addonName, setAddonName] = useState("");
  const [addonPrice, setAddonPrice] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchAddon = async () => {
      try {
        const { data } = await userRequest.get(`/transport-addon/${schoolId}/${addonId}`);
        console.log(data);
        //setAddonDetails(data);
        setAddonName(data.add_on_name);
        setAddonPrice(data.add_on_price);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAddon();
  }, [addonId, schoolId]);

  const goBack = () => {
    // Go back to the previous page
    history.goBack();
  };
  
  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      await userRequest.put(`/transport-addon/${schoolId}/${addonId}`,
        { 
          add_on_name: addonName, 
          add_on_price: addonPrice,
        });
      //console.log(data);
      setSuccess(true);
      setTimeout(() => {
          setSuccess(false);
          history.push("/addons");
      }, 3000);
    } catch (error) {
      console.log(error);
      setError(error.response.data);
      setTimeout(() => {
        setError("");
      }, 5000);
    }
    setLoading(false);
  };
  return (
    <div className="m-2 md:m-10 mt-24  p-2 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
      <button onClick={goBack} className="inline-flex justify-center py-2 px-6 mb-2 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"><i class="fad fa-arrow-left"></i></button>
      <Header category="Set Up" title="Update Extra Fee" />
      <div className="py-2 items-right">
        <div className="mt-2 max-w-full">
          <form onSubmit={submitHandler}>
              <div className="grid grid-cols-1 gap-6">
                  {error &&
                    <div className="alert alert-warning d-flex align-items-center" role="alert">
                      <i className="fal fa-lock pr-10"></i>
                      <div>
                          {error}
                      </div>
                    </div>
                  }
                  {success &&
                    <div className="alert alert-success d-flex align-items-center" role="alert">
                      <i className="fal fa-lock pr-10"></i>
                      <div>
                        <b>Success!</b> Fee information Updated!
                      </div>
                    </div>
                  }

                  <label className="block">
                    <span className="text-gray-700 dark:text-gray-200">Extra-Fee Name <small>(Lesson, Excursion, Uniform, etc)</small></span>
                    <input type="text" onChange={(e) => setAddonName(e.target.value)} value={addonName} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder="Add-on Name" />
                  </label>
                  <label className="block">
                    <span className="text-gray-700 dark:text-gray-200">Extra-Fee Price</span>
                    <input type="number" onChange={(e) => setAddonPrice(e.target.value)} value={addonPrice} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder="e.g 20000" />
                  </label>
                  <div className="px-4 py-3 text-right sm:px-6">
                    {loading ?
                      (
                          <ButtonLoading styles="inline-flex justify-center py-3 px-8 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-600" />
                      ) : (
                          <button type="submit" className="inline-flex justify-center py-3 px-8 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Save Fee</button>
                      )
                    }
                  </div>
              </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default UpdateTransport;
