import React, { createContext, useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { userRequest } from '../utils/requestMethods';

const StateContext = createContext();

const initialState = {
  userProfile: false,
  notification: false,
};

const useUser = () => {
  const [user, setUser] = useState(null);
  const [schoolId, setSchoolId] = useState("");

  useEffect(() => {
    const tokenWithUser = JSON.parse(localStorage.getItem("tokenWithUser"));
    if (tokenWithUser) {
      setUser(tokenWithUser);
      setSchoolId(tokenWithUser?.schools?.schools[0]?._id);
    }
  }, []);

  return { user, schoolId };
};

const useSchool = (schoolId) => {
  const [school, setSchool] = useState(null);

  useEffect(() => {
    const fetchSchool = async () => {
      if (schoolId) {
        try {
          const { data } = await userRequest.get(`schools/${schoolId}`);
          setSchool(data);
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchSchool();
  }, [schoolId]);

  return { school };
};

export const ContextProvider = ({ children }) => {
  const [screenSize, setScreenSize] = useState(undefined);
  const currentColor = '#4543B5';
  const [currentMode, setCurrentMode] = useState('Light');
  const [themeSettings, setThemeSettings] = useState(false);
  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);

  const { user, schoolId } = useUser();
  const { school } = useSchool(schoolId);

  const setMode = useCallback((e) => {
    setCurrentMode(e.target.value);
    localStorage.setItem('themeMode', e.target.value);
  }, []);

  const setColor = useCallback((color) => {
    localStorage.setItem('colorMode', color);
  }, []);

  const handleClick = useCallback((clicked) => setIsClicked({ ...initialState, [clicked]: true }), []);

  const contextValue = useMemo(() => ({
    user,
    school,
    currentColor,
    currentMode,
    activeMenu,
    screenSize,
    setScreenSize,
    handleClick,
    isClicked,
    initialState,
    setIsClicked,
    setActiveMenu,
    setCurrentMode,
    setMode,
    setColor,
    themeSettings,
    setThemeSettings
  }), [user, school, currentColor, currentMode, activeMenu, screenSize, isClicked, themeSettings, setMode, setColor]);

  return (
    <StateContext.Provider value={contextValue}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
