import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Header, ButtonLoading } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { userRequest } from '../utils/requestMethods';

const Compliance = () => {
    const { user } = useStateContext();
    const accessCode = user?.access_token;
    const userId = user?._id;

    const [users, setUsers] = useState({});
    const [email, setEmail] = useState(users.email);
    const [firstname, setFirstname] = useState(users.firstname);
    const [lastname, setLastname] = useState(users.lastname);
    const [mobile_number, setMobileNumber] = useState(users.mobile_number);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    //console.log(email + " school");
    //console.log(firstname + " email");
    //console.log(mobile_number + " address");
    useEffect(() => {
      const fetchUser = async () => {
        
        try {
          const { data } = await userRequest.get(`users/${userId}`);
            //console.log(data);
            setUsers(data);
        } catch (error) {
          console.log(error);
        }
      };
      fetchUser();
    }, [accessCode, userId]);

    useEffect(() => {
        const updateUser = async () => {
            if (users){
                setEmail(users.email);
                setFirstname(users.firstname);
                setLastname(users.lastname);
                setMobileNumber(users.mobile_number);
            }
        };
        updateUser();
    }, [users]);

    const submitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!email || !firstname || !lastname || !mobile_number) {
            setError("Please fill all the feilds");
            setTimeout(() => {
                setError("");
            }, 5000);
            setLoading(false);
            return;
        }
        try {
            const res = await userRequest.put(`users/${userId}`,
                {
                    firstname,
                    lastname,
                    email,
                    mobile_number,
                });
            //console.log(res.data);
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
            }, 5000);
        } catch (error) {
            //console.log(error);
            setError(error.response.data);
            setTimeout(() => {
                setError("");
            }, 5000);
        }
        setLoading(false);
    };
    return (
        <div className="m-2 md:m-10 mt-24  p-2 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
            <Header category="KYC" title="Personal Information" />
            <div className="mt-2 max-w-full divide-y">
                <div className="py-2">
                    <h3 className="text-1xl font-bold dark:text-gray-200">Contact Information</h3>
                    <hr />
                </div>
            </div>
            <div className="py-2 items-right">
                <div className="max-w-xl mx-auto mt-3 divide-y">
                    <form onSubmit={submitHandler}>
                        <div className="grid grid-cols-1 gap-6">
                            {error &&
                                <div className="alert alert-warning d-flex align-items-center" role="alert">
                                    <i className="fal fa-lock pr-10"></i>
                                    <div>
                                        {error}
                                    </div>
                                </div>
                            }
                            {success &&
                                <div className="alert alert-success d-flex align-items-center" role="alert">
                                    <i className="fal fa-lock pr-10"></i>
                                    <div>
                                        Information Updated Successfully
                                    </div>
                                </div>
                            }
                            <label className="block">
                                <span className="text-gray-700 dark:text-gray-200">First Name</span>
                                <input type="text" onChange={(e) => setFirstname(e.target.value)} defaultValue={users.firstname} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                            </label>
                            <label className="block">
                                <span className="text-gray-700 dark:text-gray-200">Last Name</span>
                                <input type="text" onChange={(e) => setLastname(e.target.value)} defaultValue={users.lastname} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                            </label>
                            <label className="block">
                                <span className="text-gray-700 dark:text-gray-200">Email Address</span>
                                <input type="email" onChange={(e) => setEmail(e.target.value)} defaultValue={users.email} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                            </label>
                            {/*<label className="block">
                                <span className="text-gray-700 dark:text-gray-200">Date of Birth </span>
                                <input type="date" className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"defaultValue="22/10/1994"  />
                            </label>*/}
                            <label className="block">
                                <span className="text-gray-700 dark:text-gray-200">Contact Person Phone</span>
                                <input type="tel" onChange={(e) => setMobileNumber(e.target.value)} defaultValue={users.mobile_number} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                            </label>
                            <div className="px-4 py-3 text-right sm:px-6">
                                {loading ?
                                    (
                                        <ButtonLoading styles="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600" />
                                    ) : (
                                        <button type="submit" className="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Save Details</button>
                                    )
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/*<div className="mt-2 max-w-full divide-y">
                <div className="py-2">
                    <h3 className="text-1xl font-bold dark:text-gray-200">Identification Information</h3>
                    <hr />
                </div>
            </div>
            <div className="py-2 items-right">
                <div className="max-w-xl mx-auto mt-3 divide-y">
                    <div className="grid grid-cols-1 gap-6">
                        <label className="block">
                            <span className="text-gray-700 dark:text-gray-200">National Identifiaction Number (NIN)</span>
                            <input type="text" defaultValue={users.bvn} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                        </label>
                        <div className="px-4 py-3 text-right sm:px-6">
                            <button type="submit" className="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Save NIN</button>
                        </div>
                    </div>
                </div>
            </div>*/}
            <hr />
            <div className="px-4 py-3 sm:px-6 mb-2 block">
                <Link to="/compliance/business">
                    <button className="float-right inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Next</button>
                </Link>
                <button disabled className="float-left inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-400">Back</button>
            </div>
        </div>
    );
};
export default Compliance;