import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { FiArrowRight } from 'react-icons/fi';
import { ImSpinner } from "react-icons/im";
import { displaySchoolTerm } from '../utils/schoolYearUtils';
import { formatCurrency } from '../utils/currencyUtils';
import { exportToCSV } from '../utils/exportCSV';
import CustomPagination from './CustomPagination/CustomPagination';

const customStyles = {
    headRow: {
      style: {
        backgroundColor: 'rgb(248, 250, 252)',
        fontWeight: "bold",
      },
    },
};

const TransactionTable = ({ transactions, loading, nameOfClass }) => {
  const [filterText, setFilterText] = useState('');
  
    const columns = [
      {
        name: "Parent Name",
        cell: row => row.user ? `${row?.user?.firstname} ${row?.user?.lastname}` : 'N/A'
      },
      {
        name: "Student Name",
        cell: row => row.child ? `${row.child.firstname} ${row.child.lastname}` : 'N/A'
      },
      {
        name: "Student Class",
        cell: row => row?.class?.class_name
      },
      {
        name: "Total Fees",
        cell: row => formatCurrency(row?.total_amount)
      },
      {
        name: "Amount Paid",
        cell: row => formatCurrency(row?.amount_paid)
      },
      {
        name: "Discount",
        cell: row => formatCurrency(row?.discount?.amount || 0) + " - (" + (row?.discount?.percentage || 0) + "%)"
      },
      {
        name: "Term",
        cell: row => displaySchoolTerm(row?.term) + " - " + row?.session
      },
      {
        cell: (row) => <Link to={`/class-transaction/payment/${row?._id}/${row?.child?._id}`}><button style={{ color: '#1e4db7', backgroundColor: '#91a6f5' }} className="text-md opacity-0.9 rounded-full p-3 hover:drop-shadow-xl"><FiArrowRight /></button></Link>,
        ignoreRowClick: true,
        button: true,
      },
    ];
  
    const filteredData = transactions?.filter((item) =>
      (item.child?.firstname?.toLowerCase().includes(filterText.toLowerCase()) || '') ||
      (item.child?.lastname?.toLowerCase().includes(filterText.toLowerCase()) || '') ||
      (item.user?.firstname?.toLowerCase().includes(filterText.toLowerCase()) || '') ||
      (item.user?.lastname?.toLowerCase().includes(filterText.toLowerCase()) || '')
    );
  
    return (
      <div className="rounded-2xl">
        <div className="flex justify-between items-center mb-4">
          <input
            type="text"
            className="px-4 py-2 rounded-lg bg-gray-100 border-gray-100 text-gray-900"
            placeholder="Search..."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <button onClick={() => exportToCSV(filteredData, columns, `${nameOfClass}-payments.csv`)} className="inline-flex justify-center py-1 px-8 shadow-sm text-sm font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 float-right">
            Export to CSV
          </button>
        </div>
        <DataTable
          data={filteredData}
          columns={columns}
          getRowId={(row) => row._id}
          pagination
          paginationComponentOptions={{
            rowsPerPageText: 'Rows per page:',
            rangeSeparatorText: 'of',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'All',
          }}
          paginationComponent={CustomPagination}
          paginationRowsPerPageOptions={[10, 20, 30, 50]}
          customStyles={customStyles}
          progressPending={loading}
          progressComponent={
            <ImSpinner className="animate-spin text-4xl text-gray-400" />
          }
        />
      </div>
    );
  };

export default TransactionTable