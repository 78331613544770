import React from 'react'

const BackButton = ({ history }) => {

    const goBack = () => {
        // Go back to the previous page
        history.goBack();
    };

  return (
    <div>
        <button onClick={goBack} className="flex items-center justify-center py-2 px-6 mb-2 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-800 hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"><i class="fad fa-arrow-left"></i></button>
    </div>
  )
}

export default BackButton