import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { useFormik } from "formik";
import { signupSchema } from '../../utils/formSchema';
import { publicRequest } from '../../utils/requestMethods';
import './css/default.css'
import './css/style.css'
import { ButtonLoading } from '../../components';
import logo from "../../data/logo/logo.png";
import circle from './img/circle.png';
import dot from './img/dot.png';
import flower from './img/flower.png';
import man2 from './img/man-2.png';
import man3 from './img/man-3.png';
import signup from './img/sign-up.png';
import zigzag from './img/zigzag.png';

const SignIn = ({ history }) => {

   const [success, setSuccess] = useState("");
   const [error, setError] = useState("");
   const [loading, setLoading] = useState(false);

   const {
      values,
      errors,
      touched,
      handleBlur,
      handleChange,
      handleSubmit,
    } = useFormik({
      initialValues: {
         firstname: "",
         lastname: "",
         school_name: "",
         mobile_number: "",
         email: "",
         password: "",
         confirmPassword: "",
      },
      validationSchema: signupSchema,
      onSubmit: (values) => {
         // Handle form submission here
         registerHandler(values);
         //console.log(values);
       },
    });

const registerHandler = async (values) => {
   setLoading(true);

   try {
      await publicRequest.post("auth/school", values);

      setSuccess("Account created successfully");
      localStorage.setItem("verifyEmail", values.email);
      setTimeout(() => {
         setSuccess("");
         history.push("/welcome/school");
      }, 2000);
   } catch (error) {
      console.log(error);
      setError(error.response.data);
      setTimeout(() => {
         setError("");
      }, 5000);
      setLoading(false);
   }
};

    return (
        <main>
          <section className="signup__area po-rel-z1 p-t-40 pb-100">
            <div className="sign__shape">
               <img className="man-1" src={man3} alt="" />
               <img className="man-2" src={man2} alt="" />
               <img className="circle" src={circle} alt="" />
               <img className="zigzag" src={zigzag} alt="" />
               <img className="dot" src={dot} alt="" />
               <img className="bg" src={signup} alt="" />
               <img className="flower" src={flower} alt="" />
            </div>
            <div className="row">
               <div className="col-xxl-8 offset-xxl-2 col-xl-8 offset-xl-2">
                  <div className="section__title-wrapper text-center m-b-40">
                     <img src={logo} alt="logo" className="mx-auto" width="250" />
                  </div>
               </div>
            </div>
            <div className="max-w-xl mx-auto divide-y p-3">
               {success &&
                  <div className="alert alert-success d-flex align-items-center" role="alert">
                     <i className="fal fa-check pr-10"></i>
                     <div>
                        {success}
                     </div>
                  </div>
               }
               {error && 
                  <div className="alert alert-warning d-flex align-items-center" role="alert">
                     <i className="fal fa-lock pr-10"></i>
                     <div>
                        {error}
                     </div>
                  </div>
               }
               <div className="sign__wrapper white-bg mx-auto">
                  <div className="sign__header mb-35">
                     <div className="sign__in text-center">
                        <h3 className="text-2xl font-bold section__title">Create a free Account.</h3>
                     </div>
                  </div>
                  <div className="sign__form">
                      <form onSubmit={handleSubmit}>
                        <div className="sign__input-wrapper mb-25">
                           <h5>Full Name</h5>
                           <div className="lg:grid lg:grid-cols-2 lg:gap-x-2">
                              <div className="sign__input mb-2">
                                 <input type="text" id="firstname" placeholder="First name" value={values.firstname} onBlur={handleBlur} onChange={handleChange} required/>
                                 <i className="fal fa-user"></i>
                                 {errors.firstname && touched.firstname &&
                                 <p className="mt-2 text-sm text-red-600 dark:text-red-500"><span className="font-medium">Oops!</span> {errors.firstname}</p>}
                              </div>
                              <div className="sign__input">
                                 <input type="text" id="lastname" placeholder="Last name" value={values.lastname} onBlur={handleBlur} onChange={handleChange} required />
                                 <i className="fal fa-user"></i>
                                 {errors.lastname && touched.lastname &&
                                 <p className="mt-2 text-sm text-red-600 dark:text-red-500"><span className="font-medium">Oops!</span> {errors.lastname}</p>}
                              </div>
                           </div>
                        </div>
                        <div className="sign__input-wrapper mb-25">
                           <h5>Business/School Name</h5>
                           <div className="sign__input">
                              <input type="text" id="school_name" placeholder="School name" value={values.school_name} onBlur={handleBlur} onChange={handleChange} required/>
                              <i className="fal fa-school"></i>
                              {errors.school_name && touched.school_name &&
                                 <p className="mt-2 text-sm text-red-600 dark:text-red-500"><span className="font-medium">Oops!</span> {errors.school_name}</p>}
                           </div>
                        </div>
                        <div className="sign__input-wrapper mb-25">
                           <h5>Mobile Number</h5>
                           <div className="sign__input">
                              <input type="tel" id="mobile_number" placeholder="Mobile Number" pattern="(0)\d{10}" value={values.mobile_number} onBlur={handleBlur} onChange={handleChange} required/>
                              <i className="fal fa-phone"></i>
                              {errors.mobile_number && touched.mobile_number &&
                                 <p className="mt-2 text-sm text-red-600 dark:text-red-500"><span className="font-medium">Oops!</span> {errors.mobile_number}</p>}
                           </div>
                        </div>
                        <div className="sign__input-wrapper mb-25">
                           <h5>Work email</h5>
                           <div className="sign__input">
                              <input type="email" id="email" placeholder="e-mail address" value={values.email} onBlur={handleBlur} onChange={handleChange} required/>
                              <i className="fal fa-envelope"></i>
                              {errors.email && touched.email &&
                                 <p className="mt-2 text-sm text-red-600 dark:text-red-500"><span className="font-medium">Oops!</span> {errors.email}</p>}
                           </div>
                        </div>
                        <div className="sign__input-wrapper mb-25">
                           <h5>Password</h5>
                           <div className="lg:grid lg:grid-cols-2 lg:gap-x-2">
                              <div className="sign__input mb-2">
                                 <input type="password" id="password" placeholder="Password" value={values.password} onBlur={handleBlur} onChange={handleChange} required/>
                                 <i className="fal fa-lock"></i>
                                 {errors.password && touched.password &&
                                 <p className="mt-2 text-sm text-red-600 dark:text-red-500"><span className="font-medium">Oops!</span> {errors.password}</p>}
                              </div>
                              <div className="sign__input">
                                 <input type="password" id="confirmPassword" placeholder="Confirm Password" value={values.confirmPassword} onBlur={handleBlur} onChange={handleChange} required/>
                                 <i className="fal fa-lock"></i>
                                 {errors.confirmPassword && touched.confirmPassword &&
                                 <p className="mt-2 text-sm text-red-600 dark:text-red-500"><span className="font-medium">Oops!</span> {errors.confirmPassword}</p>}
                              </div>
                           </div>
                        </div>
                        <div className="sign__action d-sm-flex justify-content-between mb-30">
                            <div className="sign__agree d-flex align-items-center">
                                <input className="m-check-input" type="checkbox" id="acceptedTos" required/>
                                <label className="m-check-label" htmlFor="acceptedTos">
                                    By signing up, you are creating an Acceede account, and you agree to Acceede’s <a href="https://acceede.com/terms-conditions" target="_blank">Terms of Use</a> and <a href="https://acceede.com/privacy-policy" target="_blank">Privacy Policy</a>.
                                </label>
                           </div>
                        </div>
                        {loading ?
                           (
                              <ButtonLoading styles="e-btn w-100 auth-btn" />
                           ) : (
                              <button type="submit" className="e-btn w-100 auth-btn"> <span></span> Sign Up</button>
                           )
                        }
                        <div className="sign__new text-center mt-10">
                           <p>Already on Acceede? <Link to="/signin">Sign In</Link></p>
                        </div>
                     </form>
                  </div>
               </div>
            </div>
         </section>
      </main> 
    );
};
export default SignIn;
