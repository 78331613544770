import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Header, ButtonLoading } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { userRequest } from '../utils/requestMethods';

const BusinessCompliance = () => {
    const { user, school } = useStateContext();
    const accessCode = user?.access_token;
    //const userId = user?._id;
    const schoolId = school?._id;

    const [schools, setSchools] = useState({});
    const [school_name, setSchoolName] = useState("");
    const [school_email, setSchoolEmail] = useState("");
    const [school_address, setSchoolAddress] = useState("");
    const [school_state, setSchoolState] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchSchool = async () => {
            
            try {
                const { data } = await userRequest.get(`schools/${schoolId}`);
                //console.log(data);
                setSchools(data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchSchool();
    }, [accessCode, schoolId]);

    useEffect(() => {
        const updateSchool = async () => {
            if (schools){
                setSchoolName(schools.school_name);
                setSchoolEmail(schools.school_email);
                setSchoolAddress(schools.school_address);
                setSchoolState(schools.school_state);
            }
        };
        updateSchool();
    }, [schools]);

    const submitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!school_name || !school_email || !school_address || !school_state) {
            setError("Please fill all the feilds");
            setTimeout(() => {
                setError("");
            }, 5000);
            setLoading(false);
            return;
        }
        
        try {
            await userRequest.put(`schools/${schoolId}`,
                {
                    school_name,
                    school_email,
                    school_address,
                    school_state,
                });
            //console.log(res.data);
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
            }, 5000);
        } catch (error) {
            //console.log(error);
            setError(error.response.data);
            setTimeout(() => {
                setError("");
            }, 5000);
        }
        setLoading(false);
    };
    return (
        <div className="m-2 md:m-10 mt-24  p-2 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
            <Header category="KYC" title="Business Information" />
            <div className="mt-2 max-w-full divide-y">
                <div className="py-2">
                    <h3 className="text-1xl font-bold dark:text-gray-200">School Information</h3>
                    <hr />
                </div>
            </div>
            <div className="py-2 items-right">
                <div className="max-w-xl mx-auto mt-3 divide-y">
                    <form onSubmit={submitHandler}>
                        <div className="grid grid-cols-1 gap-6">
                            {error &&
                                <div className="alert alert-warning d-flex align-items-center" role="alert">
                                    <i className="fal fa-lock pr-10"></i>
                                    <div>
                                        {error}
                                    </div>
                                </div>
                            }
                            {success &&
                                <div className="alert alert-success d-flex align-items-center" role="alert">
                                    <i className="fal fa-lock pr-10"></i>
                                    <div>
                                        Information Updated Successfully
                                    </div>
                                </div>
                            }
                            <label className="block">
                                <span className="text-gray-700 dark:text-gray-200">Name of School</span>
                                <input type="text" onChange={(e) => setSchoolName(e.target.value)}  defaultValue={schools.school_name} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                            </label>
                            <label className="block">
                                <span className="text-gray-700 dark:text-gray-200">School Email</span>
                                <input type="text" onChange={(e) => setSchoolEmail(e.target.value)}  defaultValue={schools.school_email} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                            </label>
                            <label className="block">
                                <span className="text-gray-700 dark:text-gray-200">School Address</span>
                                <input type="text" onChange={(e) => setSchoolAddress(e.target.value)}  defaultValue={schools.school_address} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                            </label>
                            <label className="block">
                                <span className="text-gray-700 dark:text-gray-200">State/Location</span>
                                <input type="text" onChange={(e) => setSchoolState(e.target.value)}  defaultValue={schools.school_state} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                            </label>
                            <div className="px-4 py-3 text-right sm:px-6">
                                {loading ?
                                    (
                                        <ButtonLoading styles="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600" />
                                    ) : (
                                        <button type="submit" className="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Save Details</button>
                                    )
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <hr />
            <div className="px-4 py-3 sm:px-6 mb-2">
                <Link to="/compliance/payment">
                    <button className="float-right inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Next</button>
                </Link>
                <Link to="/compliance/personal">
                    <button className="float-left inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Back</button>
                </Link>
            </div>
        </div>
    );
};
export default BusinessCompliance;