import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { FiArrowRight } from 'react-icons/fi';
import { ImSpinner } from "react-icons/im";
import { userRequest } from '../utils/requestMethods';
import { CustomPagination, Header } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import useKYCStatusRedirect from '../hooks/useKYCStatusRedirect';
import { generateSchoolSessions } from '../utils/schoolYearUtils';
import { exportToCSV } from '../utils/exportCSV';
import { formatCurrency } from '../utils/currencyUtils';

const customStyles = {
  headRow: {
    style: {
      backgroundColor: 'rgb(248, 250, 252)',
      fontWeight: "bold",
    },
  },
};

const AllTransactions = () => {
  useKYCStatusRedirect("/flexpay");
  const schoolSessions = generateSchoolSessions(2022, 20);
  const { user, school, currentColor } = useStateContext();
  const [transactions, setTransactions] = useState();
  const [term, setTerm] = useState("");
  const [session, setSession] = useState("");
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const userId = user?._id;
  const schoolId = school?._id;
  const [filterText, setFilterText] = useState('');


  useEffect(() => {
    const fetchTransactions = async () => {
        try {
          setLoading(true);
          const { data } = await userRequest.get(`/loans/school/${schoolId}?limit=200`);
          //console.log(data);
          setTransactions(data.loansAndRecova);
          setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };
    fetchTransactions();
  }, [userId, schoolId]);

  //Do search
  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const { data } = await userRequest.get(`/loans/school/${schoolId}?limit=1000&sort_by=date&order=des&term=${term}&session=${session}`);
      //console.log(data);
      setTransactions(data.transactions);
    } catch (error) {
        console.log(error);
    }
    setLoading(false);
  };

  const columns = [
    {
      name: "Parent Name",
      cell: row => row.user?.firstname +" "+ row.user?.lastname
    },
    {
      name: "Total Amount Requested",
      cell: row => (<b>{formatCurrency(row.total_wards_fee)}</b>),
      exportCell: row => row.total_wards_fee || 'N/A', // This is for CSV export
    },
    {
      name: "Credit Decision",
      cell: (row) => (<button style={row.credit_decision === "approved" ? { background: 'rgb(139, 231, 139)' } : row.credit_decision === "declined" ? { background: 'rgb(227, 79, 79)' } : { background: 'rgb(227, 160, 8)' }} className="text-white py-1 px-2 capitalize rounded-2xl text-md font-bold">{row.credit_decision=== "approved" ? ("Qualified") : row.credit_decision === "declined" ? ("Declined") : ("In Review")}</button>),
      exportCell: row => row.credit_decision || 'N/A', // This is for CSV export
    },
    {
      name: "Status",
      cell: (row) => (
        <button
          className={`py-1 px-2 capitalize rounded-2xl text-md font-bold 
            ${row.status === "full" ? "bg-green-200 text-green-800" : 
              row.status === "reject" ? "bg-red-200 text-red-800" : 
              row.status === "active" ? "bg-blue-200 text-blue-800" : 
              row.status === "cancelled" ? "bg-orange-200 text-orange-800" : 
              row.status === "initiated" ? "bg-cyan-200 text-cyan-800" : 
              "bg-yellow-200 text-yellow-800"}`}
        >
          {row?.status === "full" ? "Approved" : 
           row?.status === "reject" ? "Rejected" : 
           row?.status === "initiated" ? "Initiated" : 
           row?.status === "cancelled" ? "Cancelled" : 
           row?.status === "active" ? "Active" : 
           "Pending"}
        </button>
      ),
      exportCell: row => row.status || 'N/A', // This is for CSV export
    },
    {
      name: "Date",
      cell: row => (new Date(row.createdAt)).toLocaleDateString('en-NG'),
      sortable: true
    },
    {
      cell: (row) => <Link to={`/flexpay/flexpay-details/${row.loanReference}`}><button style={{ color: '#1e4db7', backgroundColor: '#91a6f5' }} className="text-md opacity-0.9 rounded-full p-3 hover:drop-shadow-xl"><FiArrowRight /></button></Link>,
      ignoreRowClick: true,
      button: true,
    },
  ];

  const filteredData = transactions?.filter((item) =>
    (item.user?.firstname?.toLowerCase().includes(filterText.toLowerCase()) || '') ||
    (item.user?.lastname?.toLowerCase().includes(filterText.toLowerCase()) || '') ||
    (item.total_wards_fee?.toString().toLowerCase().includes(filterText.toLowerCase()) || '')
  );

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 dark:bg-secondary-dark-bg bg-white rounded-3xl">
        <button type="button" onClick={() => setClicked(!clicked)} style={{ backgroundColor: currentColor }} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 float-right">
            Filters
        </button>
        <Header category="FlexPay" title="Request" />
        {clicked ? (
          <div className="absolute right-4 z-50 bg-white border-2 shadow-lg rounded-lg p-4 w-64" style={{ top: "12rem" }}>
            <div className="md:grid gap-6 mb-6 grid-cols-1">
              <div>
                <label htmlFor="startDate" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Term</label>
                <select id="term" onChange={(e) => setTerm(e.target.value)} defaultValue={term} className="block w-full mt-1 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0">
                  <option value="">Select</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
              </div>
              <div>
                <label htmlFor="endDate" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Session</label>
                <select id="session" onChange={(e) => setSession(e.target.value)} defaultValue={session} className="block w-full mt-1 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" required>
                  <option value="">Select</option>
                  {schoolSessions.map(session => (
                    <option key={session.value} value={session.value}>
                      {session.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mt-2">
                <button type="submit" disabled={loading} onClick={submitHandler} className="py-2 px-8 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-600 disabled:bg-indigo-400 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Search</button>
              </div>
            </div>
          </div>
        ) : null }
        {/* filter and export */}
        <div className="flex justify-between items-center mb-4">
          <input
            type="text"
            className="px-4 py-2 rounded-lg bg-gray-100 border-gray-100 text-gray-900"
            placeholder="Search..."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <button onClick={() => exportToCSV(filteredData, columns, `Flexpay-requests.csv`)} className="inline-flex justify-center py-1 px-8 shadow-sm text-sm font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 float-right">
            Export to CSV
          </button>
        </div>
        <DataTable
          data={filteredData}
          columns={columns}
          getRowId={(row) => row._id}
          pagination
          paginationComponentOptions={{
            rowsPerPageText: 'Rows per page:',
            rangeSeparatorText: 'of',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'All',
          }}
          paginationComponent={CustomPagination}
          paginationRowsPerPageOptions={[10, 20, 30, 50]}
          customStyles={customStyles}
          progressPending={loading}
          progressComponent={
            <ImSpinner className="animate-spin text-4xl text-gray-400" />
          }
        />
    </div>
  );
};
export default AllTransactions;
