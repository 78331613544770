import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { userRequest } from '../../utils/requestMethods';
import { Header, ButtonLoading, SuccessModal } from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';
import { ImSpinner } from "react-icons/im";

const KYCDocumentUpload = ({ userId, documents }) => {
  const [documentStates, setDocumentStates] = useState(
    documents.reduce((acc, doc) => {
      acc[doc.docId] = { textData: '', file: null, saving: false, error: "", success: "" };
      return acc;
    }, {})
  );

  const handleFileChange = (docId, e) => {
    const selectedFile = e.target.files[0];
    setDocumentStates(prevState => ({
      ...prevState,
      [docId]: { ...prevState[docId], file: selectedFile }
    }));
  };

  const handleTextChange = (docId, e) => {
    setDocumentStates(prevState => ({
      ...prevState,
      [docId]: { ...prevState[docId], textData: e.target.value }
    }));
  };

  const handleSubmit = async (docId, e) => {
    e.preventDefault();
    const { textData, file } = documentStates[docId];

    if (!textData && !file) {
      alert('Please provide text data or select a file.');
      return;
    }

    const formData = new FormData();
    if (textData) formData.append('textData', textData);
    if (file) formData.append('fileData', file);

    try {
      setDocumentStates(prevState => ({
        ...prevState,
        [docId]: { ...prevState[docId], saving: true }
      }));
      const { data } = await userRequest.post(`/schools/${userId}/${docId}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(data);
      //alert('Document uploaded successfully!');
      setDocumentStates(prevState => ({
        ...prevState,
        [docId]: { ...prevState[docId], success: 'Document uploaded successfully!', error: "" }
      }));
      setTimeout(() => {
        setDocumentStates(prevState => ({
          ...prevState,
          [docId]: { ...prevState[docId], success: "", error: "" }
        }));
      }, 2000);
    } catch (error) {
      console.error('Error uploading document:', error);
      /*if (error.response.status === 500) {
        alert('Error: No Opened Kyc Information or all Require Kyc request submitted.');
      } else {
        alert('Error uploading document, try again later');
      }*/
      let errorMsg = 'Error uploading document, try again later';
      if (error.response && error.response.status === 500) {
        errorMsg = `Error: ${error.response.data}`;
      }
      setDocumentStates(prevState => ({
        ...prevState,
        [docId]: { ...prevState[docId], error: errorMsg, success: "" }
      }));
      setTimeout(() => {
        setDocumentStates(prevState => ({
          ...prevState,
          [docId]: { ...prevState[docId], success: "", error: "" }
        }));
      }, 3000);
    } finally {
      setDocumentStates(prevState => ({
        ...prevState,
        [docId]: { ...prevState[docId], saving: false }
      }));
    }
  };

    return (
        <div className="block mb-6">
          {documents.map((doc) => (
            <div key={doc._id} className="block mb-6">
              {documentStates[doc.docId].error && (
                <div className="alert alert-warning flex items-center" style={{ paddingTop: "5px", paddingBottom: "5px"}} role="alert">
                  <i className="fal fa-lock pr-2"></i>
                  <div>{documentStates[doc.docId].error}</div>
                </div>
              )}
              {documentStates[doc.docId].success && (
                <div className="alert alert-success flex items-center" style={{ paddingTop: "5px", paddingBottom: "5px"}} role="alert">
                  <i className="fal fa-check pr-2"></i>
                  <div>{documentStates[doc.docId].success}</div>
                </div>
              )}
              <span className="text-gray-700 dark:text-gray-200">{doc.type.replace(/_/g, ' ')}</span>
              <div className="mt-1 text-sm text-gray-500 dark:text-gray-300">{doc.description} {doc.type === 'BN_NUMBER' ? ("format: bnxxxxxxxx") : (" ") }</div>
              <form onSubmit={(e) => handleSubmit(doc.docId, e)}>
                <div className="lg:grid lg:grid-cols-4 lg:gap-x-2">
                  <div className="lg:col-span-3 mb-2">
                    {doc.field_type === 'TEXT' ? (
                      <input
                        type="text"
                        value={documentStates[doc.docId].textData}
                        onChange={(e) => handleTextChange(doc.docId, e)}
                        placeholder="Enter additional text data"
                        className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                      />
                    ) : (
                      <input
                        type="file"
                        onChange={(e) => handleFileChange(doc.docId, e)}
                        className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0"
                      />
                    )}
                  </div>
                  <div className="lg:col-span-1 mb-2">
                    {documentStates[doc.docId].saving ? (
                      <ButtonLoading styles="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-400" />
                    ) : (
                      <button type="submit" className="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Save</button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          ))}
        </div>
    );
};
  

const Documents = ({ history }) => {
    const { school } = useStateContext();
    const schoolId = school?._id;

    const [userInfo, setUserInfo] = useState({});
    const [requiredDoc, setRequiredDoc] = useState([]);
    //const [error, setError] = useState("");
    //const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = () => {
      setIsModalOpen(!isModalOpen);
    };

    useEffect(() => {
        const fetchTransactions = async () => {
          try {
            setLoading(true);
            const { data } = await userRequest.get(`schools/${school._id}`);
            //console.log(data);
            setUserInfo(data);
            setRequiredDoc(data.kyc_required_docs.data);
            //console.log(data.kyc_required_docs.data);
            setLoading(false);
          } catch (error) {
            console.log(error);
          }
        };
        fetchTransactions();
    }, [school]);

    const goBack = () => {
        // Go back to the previous page
        history.goBack();
    };
    

    return (
        <div className="m-2 md:m-10 mt-24 shadow p-2 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
          <Header category="Onboarding" title="Compliance Documents" />
          <div className="py-2">
            <button onClick={goBack} className="inline-flex justify-center py-2 px-6 mb-2 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-700 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"><i class="fad fa-arrow-left"></i></button>
            <Link to="/onboarding/get-started/directors-identity">
              <button className="float-right inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-700">Directors Identity <i class="fad fa-arrow-right mt-1 ml-2"></i></button>
            </Link>
          </div>
            
            <div className="mt-2 max-w-full divide-y">
                <div className="py-2">
                    <h3 className="text-1xl font-bold dark:text-gray-200">
                        Kindly provide the required business documents below to complete business activation.
                    </h3>
                    <hr />
                </div>
            </div>
            {loading ? (
                <div className="py-4 overflow-scroll md:overflow-hidden flex justify-center items-center h-full">
                    <div className="max-w-xl mx-auto mt-3 divide-y">
                        <div className="grid grid-cols-1 gap-6">
                            <p className="block text-center">
                                <ImSpinner className="animate-spin text-4xl text-gray-400" />
                            </p>
                        </div>
                    </div>
                </div>
            ) : (
            <>
                <div className="py-2 items-right">
                    <div className="max-w-xl mx-auto mt-3 divide-y">
                        <div className="grid grid-cols-1 gap-6">
                            <KYCDocumentUpload userId={schoolId} documents={requiredDoc} />
                        </div>
                    </div>
                </div>
            </>
            )}
            <hr />
            {/*<div className="px-4 py-3 sm:px-6 mb-2">
              <button type="button" onClick={toggleModal} className="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-700 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 float-right">
                Done
              </button>
            </div>*/}
            {isModalOpen && 
              <SuccessModal 
              closeModal={toggleModal} 
              />}
        </div>
    );
};
export default Documents;