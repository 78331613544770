import React, { useState } from 'react';
import { userRequest } from '../utils/requestMethods';
import { Header, ButtonLoading } from '../components';
import { useStateContext } from '../contexts/ContextProvider';

const AddclassName = ({ history }) => {
  const { school } = useStateContext();
  const schoolId = school?._id;

  const [class_name, setClassname] = useState("");
  const [class_price, setClassPrice] = useState("");
  const [level_of_education, setLevelOfEducation] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [checked, setChecked] = useState(false);

  //console.log(checked);
  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!class_name || !class_price || !level_of_education) {
      setError("Please Fill all the Feilds");
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
      return;
    }

    try {
      await userRequest.post("/classes",
        { 
          schoolId,
          class_name, 
          class_price,
          level_of_education,
        });

      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    } catch (error) {
      console.log(error);
      setError(error.response.data);
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
    }
    setLoading(false);
  };
  return (
    <div className="m-2 md:m-10 mt-24  p-2 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
      <Header category="Set Up" title="Add New Class" />
      <div className="py-2 items-right">
        <div className="mt-2 max-w-full">
          <form onSubmit={submitHandler}>
            <div className="grid grid-cols-1 gap-6">
              {error &&
                <div className="alert alert-warning d-flex align-items-center" role="alert">
                  <i className="fal fa-lock pr-10"></i>
                  <div>
                    {error}
                  </div>
                </div>
              }
              {success &&
                <div className="alert alert-success d-flex align-items-center" role="alert">
                  <i className="fal fa-lock pr-10"></i>
                  <div>
                    New Class Added
                  </div>
                </div>
              }
              
              {checked ? (
                <label className="block">
                  <span className="text-gray-700 dark:text-gray-200">Name of Class</span>
                  <input type="text" onChange={(e) => setClassname(e.target.value)} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder="Class Name" />
                </label>
              ) : (
                <label className="block">
                  <span className="text-gray-700">Name of Class</span>
                  <select onChange={(e) => setClassname(e.target.value)} className="block w-full mt-1 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0">
                    <option value="">Select</option>
                    <option value="Pre-School">Pre-School</option>
                    <option value="KG 1">KG 1</option>
                    <option value="KG 2">KG 2</option>
                    <option value="KG 3">KG 3</option>
                    <option value="Nursery 1">Nursery 1</option>
                    <option value="Nursery 2">Nursery 2</option>
                    <option value="Nursery 3">Nursery 3</option>
                    <option value="Primary 1">Primary 1</option>
                    <option value="Primary 2">Primary 2</option>
                    <option value="Primary 3">Primary 3</option>
                    <option value="Primary 4">Primary 4</option>
                    <option value="Primary 5">Primary 5</option>
                    <option value="JSS 1">JSS 1</option>
                    <option value="JSS 2">JSS 2</option>
                    <option value="JSS 3">JSS 3</option>
                    <option value="SS 1">SS 1</option>
                    <option value="SS 2">SS 2</option>
                    <option value="SS 3">SS 3</option>
                  </select>
                </label>
              )}
              <label className="block">
                <input className="m-check-input" onChange={(e) => setChecked(!checked)} type="checkbox" id="m-agree" />
                <label className="m-check-label ml-4" htmlFor="m-agree">
                  My class is not on this list
                </label>
              </label>
              <label className="block">
                <span className="text-gray-700 dark:text-gray-200">School Fees Amount</span>
                <input type="number" onChange={(e) => setClassPrice(e.target.value)} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder="20000"/>
              </label>
              {checked ? (
                <label className="block">
                  <span className="text-gray-700 dark:text-gray-200">Level of Education <small>(vocational, upskill, etc)</small></span>
                  <input type="text" onChange={(e) => setLevelOfEducation(e.target.value)} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" placeholder="(vocational, upskill, etc)" />
                </label>
              ) : (
                <label className="block">
                  <span className="text-gray-700">Level of Education</span>
                  <select onChange={(e) => setLevelOfEducation(e.target.value)} className="block w-full mt-1 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0">
                    <option value="">Select</option>
                    <option value="pre_school">Pre-School</option>
                    <option value="kindergarten">Kindergarten</option>
                    <option value="nursery">Nursery</option>
                    <option value="primary">Primary</option>
                    <option value="secondary">Secondary</option>
                  </select>
                </label>
              )
              }
              <div className="px-4 py-3 text-right sm:px-6"> 
                {loading ?
                  (
                    <ButtonLoading styles="inline-flex justify-center py-3 px-8 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-600" />
                  ) : (
                    <button type="submit" className="inline-flex justify-center py-3 px-8 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Save Class</button>
                  )
                }
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default AddclassName;
