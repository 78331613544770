import React, { useState } from 'react'
import ButtonLoading from './ButtonLoading'
import { generateSchoolSessions } from '../utils/schoolYearUtils';
import { userRequest } from '../utils/requestMethods';

const AddClassModal = ({ closeModal, school }) => {
    const schoolSessions = generateSchoolSessions(2022, 20);
    const [term, setTerm] = useState("");
    const [session, setSession] = useState("");
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const submitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            await userRequest.put(`/schools/${school._id}`, {
                term,
                session,
            });

            setSuccess("School term and session added");
            setTimeout(() => {
                setSuccess("");
                closeModal();
                //setRefresh("information");
                //navigate("/auth/kyc");
            }, 2000);
        } catch (error) {
            console.log(error);
            setError("Something went wrong, try again in a few minutes!");
            setTimeout(() => {
                setError("");
            }, 5000);
        } finally {
            setLoading(false);
        }
    };
return (
    <div>
        <div
        className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-md"
        onClick={closeModal}>
        </div>

        <div id="authentication-modal" tabIndex="-1" aria-hidden="true" className="overflow-y-auto overflow-x-hidden fixed top-3/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 w-full md:w-[100%] max-w-md h-[calc(100%-1rem)] max-h-full">
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                    <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Set School Term and Session
                        </h3>
                        <button onClick={closeModal} type="button" className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="p-4 md:p-5">
                        {error &&
                            <div className="alert alert-warning d-flex align-items-center" role="alert">
                                <i className="fal fa-lock pr-10"></i>
                                <div>{error}</div>
                            </div>
                        }
                        {success &&
                            <div className="alert alert-success d-flex align-items-center" role="alert">
                                <i className="fal fa-lock pr-10"></i>
                                <div>{success}</div>
                            </div>
                        }
                        <form onSubmit={submitHandler} class="space-y-4">
                            <div>
                                <label htmlFor="term" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Term</label>
                                <select id="term" onChange={(e) => setTerm(e.target.value)} defaultValue={term} className="block w-full mt-1 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0">
                                    <option value="">Select</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="session" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Session</label>
                                <select id="session" onChange={(e) => setSession(e.target.value)} defaultValue={session} className="block w-full mt-1 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0">
                                    <option value="">Select</option>
                                    {schoolSessions.map(session => (
                                        <option key={session.value} value={session.value}>
                                            {session.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {loading ?
                                (
                                <ButtonLoading styles="w-full text-white bg-blue-500 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center" />
                                ) : (
                                <button type="submit" className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Save Record</button>
                                )
                            }
                        </form>
                    </div>
                </div>
            </div>
        </div> 
    </div>
  )
}

export default AddClassModal