import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { userRequest } from '../utils/requestMethods';
import { BackButton, CustomPagination, Header, TransferModal } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import useKYCStatusRedirect from '../hooks/useKYCStatusRedirect';
import { FiArrowRight } from 'react-icons/fi';
import DataTable from 'react-data-table-component';
import { ImSpinner } from 'react-icons/im';
import { formatCurrency } from '../utils/currencyUtils';
import { exportToCSV } from '../utils/exportCSV';

const customStyles = {
    headRow: {
      style: {
        backgroundColor: 'rgb(248, 250, 252)',
        fontWeight: "bold",
      },
    },
};

const ExpenseRecord = ({ history }) => {
  useKYCStatusRedirect("/expenses");
  const { user, school, currentColor } = useStateContext();
  const [transactions, setTransactions] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const userId = user?._id;
  const schoolId = school?._id;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterText, setFilterText] = useState('');

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    const fetchTransactions = async () => {
        try {
          setLoading(true);
          const { data } = await userRequest.get(`transactions/${userId}/${schoolId}?&type=nip_transfer&limit=1000&sort_by=date&order=des`);
          //console.log(data);
          setTransactions(data.transactions);
          setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };
    fetchTransactions();
  }, [userId, schoolId]);

  //Do search
  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    const start = new Date(startDate).toISOString().slice(0, 10);
    const end = new Date(endDate).toISOString().slice(0, 10);
    try {
      const { data } = await userRequest.get(`transactions/${userId}/${schoolId}?limit=200&sort_by=date&order=des&from=${start}&to=${end}&type=nip_transfer`);
      //console.log(data);
      setTransactions(data.transactions);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const columns = [
    {
      name: "Amount",
      cell: row => formatCurrency(row.amount)
    },
    {
      name: "Transaction Fee",
      cell: row => formatCurrency(row.transaction_fee || 0)
    },
    {
      name: "Final Amount Paid",
      cell: row => formatCurrency(row.final_amount)
    },
    {
      name: "Purpose",
      cell: row => row.metadata ? `${row.metadata?.reason}` : 'N/A'
    },
    {
      name: "Status",
      cell: row => (
        <button type="button" style={{ background: 'rgb(139, 231, 139)' }} className="text-white py-1 px-2 capitalize rounded-2xl text-md font-bold">{row.status}</button>
      ),
      exportCell: row => row.status || 'N/A', // This is for CSV export
    },
    {
      name: "Payment Date",
      cell: row => (new Date(row.created_at)).toLocaleDateString('en-NG'),
      sortable: true
    },
    {
      cell: (row) => (
        <Link to={`/expense-details/${row._id}`}>
          <button style={{ color: '#1e4db7', backgroundColor: '#91a6f5' }}className="text-md opacity-0.9 rounded-full p-3 hover:drop-shadow-xl"><FiArrowRight /></button>
        </Link>
      ),
      ignoreRowClick: true,
      button: true, // Ignored during export
    },
  ];

  const filteredData = transactions?.filter((item) =>
    (item.metadata?.reason?.toLowerCase().includes(filterText.toLowerCase()) || '') ||
    (item.amount?.toString().toLowerCase().includes(filterText.toLowerCase()) || '')
  );  

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 dark:bg-secondary-dark-bg bg-white rounded-3xl min-h-screen">
        <div className="flex flex-wrap justify-between items-center mb-4">
            <BackButton history={history} />
            <div className="flex flex-wrap justify-center md:justify-end w-full md:w-auto">
                <button type="button" onClick={toggleModal} className="mr-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-700 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 float-right">
                    Make a Transfer
                </button>
                <button type="button" onClick={() => setClicked(!clicked)} style={{ backgroundColor: currentColor }} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 float-right">
                    Filters
                </button>
            </div>
        </div>
        <Header category="Transfer" title="Expense Record" />
        {clicked ? (
          <div className="absolute right-4 z-50 bg-white border-2 shadow-lg rounded-lg p-4 w-64" style={{ top: "12rem" }}>
            <div className="md:grid gap-6 mb-6 grid-cols-1">
              <div>
                <label htmlFor="startDate" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Start Date</label>
                <input type="date" id="startDate" onChange={(e) => setStartDate(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
              </div>
              <div>
                <label htmlFor="endDate" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">End Date</label>
                <input type="date" id="endDate" onChange={(e) => setEndDate(e.target.value)} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required />
              </div>
              <div className="mt-6">
                <button type="submit" disabled={loading} onClick={submitHandler} className="py-2 px-8 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-600 disabled:bg-indigo-400 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Search</button>
              </div>
            </div>
          </div>
        ) : null }
        <div className="flex justify-between items-center mb-4">
          <input
            type="text"
            className="px-4 py-2 rounded-lg bg-gray-100 border-gray-100 text-gray-900"
            placeholder="Search..."
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <button onClick={() => exportToCSV(filteredData, columns, 'Expenses-transactions.csv')} className="inline-flex justify-center py-1 px-8 shadow-sm text-sm font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 float-right">
            Export to CSV
          </button>
        </div>
        <DataTable
          data={filteredData}
          columns={columns}
          getRowId={(row) => row._id}
          pagination
          paginationComponentOptions={{
            rowsPerPageText: 'Rows per page:',
            rangeSeparatorText: 'of',
            selectAllRowsItem: true,
            selectAllRowsItemText: 'All',
          }}
          paginationComponent={CustomPagination}
          paginationRowsPerPageOptions={[10, 20, 30, 50]}
          customStyles={customStyles}
          progressPending={loading}
          progressComponent={
            <ImSpinner className="animate-spin text-4xl text-gray-400" />
          }
        />

        {isModalOpen && 
          <TransferModal 
            closeModal={toggleModal} 
            school={school}
          />}
    </div>
  );
};
export default ExpenseRecord;
