import React from 'react';
import { Header } from '../components';
import useKYCStatusRedirect from '../hooks/useKYCStatusRedirect';
import cashflow from '../data/cashflow02.png';

const CashflowPending = () => {
  useKYCStatusRedirect("/financing/cashflow");


  return (
    <div className="m-4 md:m-10 mt-24 p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
      <Header category="Financing" title="Cashflow" />
        <div class="mx-auto max-w-screen-xl px-4 2xl:px-0 md:pb-16">
            <div class="mx-auto max-w-5xl">
            <div class="my-8 xl:mb-16 xl:mt-12">
                <img class="mx-auto w-40 h-40 p-1 rounded shadow-lg dark:ring-gray-500" src={cashflow} alt="Bordered avatar" />
            </div>
            <h2 class="text-xl font-semibold text-gray-900 text-center .mb-7 dark:text-white sm:text-2xl">Cashflow is coming to you soon.</h2>
            <div class="mx-auto max-w-2xl space-y-6">
                <p class="text-base font-normal text-gray-500 dark:text-gray-400">
                    Cash flow financing is a solution designed specifically for your school. It allows your school access up to 90% of your total school fees revenue as  cash at the beginning of each term, providing you with the financial stability you need to thrive.
                </p>
            </div>
            </div>
        </div>
    </div>
  )
};

export default CashflowPending;
