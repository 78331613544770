import React, { useState, useRef } from 'react';
import { Header, ButtonLoading } from '../components';
import emailjs from '@emailjs/browser';
import useKYCStatusRedirect from '../hooks/useKYCStatusRedirect';
import { useStateContext } from '../contexts/ContextProvider';

const Support = () => {
  useKYCStatusRedirect("/support");
  const { user, school } = useStateContext();
  const form = useRef();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);

  emailjs.sendForm('service_nm9ycjj', 'template_f09erd3', form.current, {
      publicKey: 'Q9nSpcciwPC3RZjRa',
    })
    .then(
      () => {
        console.log('SUCCESS!');
        setSuccess("Request sent successfully");
        setLoading(false);
        setTimeout(() => {
          setSuccess("");
        }, 2000);
      },
      (error) => {
        console.log('FAILED...', error);
        setError('FAILED...', error.text);
        setTimeout(() => {
          setError("");
        }, 2000);
      }
    );
  };

  return (
    <div className="m-4 md:m-10 mt-24 p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
        <Header category="Help" title="Support Channels" />
        <div class="mt-2 max-w-full divide-y">
            <div class="grid mb-8 rounded-lg shadow-sm md:mb-12 md:grid-cols-3 bg-white">
                <figure style={{ borderRight: "1px solid rgb(229 231 235 / 1)" }} class="flex flex-col items-center justify-center p-8 text-center bg-white">
                    <figcaption class="flex items-center justify-center ">
                        <button type="button" class="text-white bg-blue-700 mr-2 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            <i class="fas fa-phone"></i>
                        </button>
                        <div class="space-y-0.5 font-medium dark:text-white text-left rtl:text-right ms-3">
                            <div>Call Us</div>
                            <div class="text-sm text-gray-500 dark:text-gray-400">09122074867</div>
                        </div>
                    </figcaption>    
                </figure>
                <figure style={{ borderRight: "1px solid rgb(229 231 235 / 1)" }} class="flex flex-col items-center justify-center p-8 text-center bg-white">
                    <figcaption class="flex items-center justify-center ">
                        <button type="button" class="text-white mr-2 bg-green-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            <i class="fab fa-whatsapp"></i>
                        </button>
                        <div class="space-y-0.5 font-medium dark:text-white text-left rtl:text-right ms-3">
                            <div>Whatsapp on</div>
                            <div class="text-sm text-gray-500 dark:text-gray-400">09122074867</div>
                        </div>
                    </figcaption>    
                </figure>
                <figure class="flex flex-col items-center justify-center p-8 text-center bg-white">
                    <figcaption class="flex items-center justify-center ">
                        <button type="button" class="text-white mr-2 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            <i class="fad fa-envelope"></i>
                        </button>            
                        <div class="space-y-0.5 font-medium dark:text-white text-left rtl:text-right ms-3">
                            <div>Email Us</div>
                            <div class="text-sm text-gray-500 dark:text-gray-400"><a href="mailto:Support@acceede.com">Support@acceede.com</a> </div>
                        </div>
                    </figcaption>    
                </figure>
            </div>
        </div>

        <div className="py-2 items-right">
            <div className="max-w-xl mx-auto mt-3 divide-y">
                {success &&
                    <div className="alert alert-success d-flex align-items-center" role="alert">
                        <i className="fal fa-check pr-10"></i>
                        <div>
                            {success}
                        </div>
                    </div>
                }
                {error && 
                    <div className="alert alert-warning d-flex align-items-center" role="alert">
                        <i className="fal fa-lock pr-10"></i>
                        <div>
                            {error}
                        </div>
                    </div>
                }
                <form ref={form} onSubmit={sendEmail}>
                <h2 class="text-xl font-semibold text-gray-900 mb-7 dark:text-white sm:text-2xl">Drop your questions here.</h2>
                    <div className="grid grid-cols-1 gap-6">
                        <label className="block">
                            <span className="text-gray-700 dark:text-gray-200">Full Name</span>
                            <input type="text" id="full_name" name="full_name" value={user?.firstname +" "+ user?.lastname} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-primary-500 focus:bg-white focus:ring-0" readOnly required />
                            <input type="text" id="busines_type" name="busines_type" value="School" hidden className="mt-1 w-full rounded-md bg-gray-100 border-transparent focus:border-primary-500 focus:bg-white focus:ring-0" />
                            <input type="text" id="email" name="email" value={user?.email} hidden className="mt-1 w-full rounded-md bg-gray-100 border-transparent focus:border-primary-500 focus:bg-white focus:ring-0" />
                            <input type="text" id="school" name="school" value={school?.school_name} hidden className="mt-1 w-full rounded-md bg-gray-100 border-transparent focus:border-primary-500 focus:bg-white focus:ring-0" />
                        </label>
                        <label className="block">
                            <span className="text-gray-700 dark:text-gray-200">Question type</span>
                            <select id="question_type" name="question_type" className="bg-gray-100 border-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required>
                                <option value="">Select</option>
                                <option value="KYC Validation">KYC Validation</option>
                                <option value="Flexpay Management">Flexpay Management</option>
                                <option value="Cashflow Service">Cashflow Service</option>
                                <option value="Settlement Management">Settlement Management</option>
                                <option value="Student Management">Student Management</option>
                                <option value="Promotion Management">Promotion Management</option>
                            </select>
                        </label>
                        <label className="block">
                            <span className="text-gray-700 dark:text-gray-200">Your question <small>(150-500 characters)</small></span>
                            <textarea id="question" name="question" rows="6" class="block w-full rounded-lg border border-gray-100 bg-gray-100 p-2.5 text-sm text-gray-900 focus:border-primary-500 focus:ring-primary-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-400 dark:focus:border-primary-500 dark:focus:ring-primary-500" required></textarea>
                        </label>
                        <div className="px-4 py-3 text-right sm:px-6">
                            {loading ?  (
                                <ButtonLoading styles="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-400" />
                            ) : (
                                <button type="submit" className="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Save Details</button>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
  )
};

export default Support;
