import React from 'react';

const Header = ({ category, title }) => (
  <div className="mb-10">
    <p className="text-md text-gray-400">{category}</p>
    <p className="text-lg font-extrabold tracking-tight text-slate-900 dark:text-gray-200">
      {title}
    </p>
  </div>
);

export default Header;
