import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { userRequest } from '../utils/requestMethods';
import { ImSpinner } from "react-icons/im";
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { ButtonLoading, Header, AddStudentModal, CustomPagination } from '../components';
import { useStateContext } from '../contexts/ContextProvider';

const customStyles = {
  headRow: {
    style: {
      backgroundColor: 'rgb(248, 250, 252)',
      fontWeight: "bold",
    },
  },
};

const Students = ({ history }) => {
  const location = useLocation();
  const classId = location.pathname.split('/')[2];
  //console.log(classId)
  const { school } = useStateContext();
  const schoolId = school?._id;
  const schoolCode = school?.code;
  const [students, setStudents] = useState([]);
  const [classInfo, setClassInfo] = useState({});
  const [file, setFile] = useState(null);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [gender, setGender] = useState("");
  const [clicked, setClicked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [accountSuccess, setAccountSuccess] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const goBack = () => {
    // Go back to the previous page
    history.goBack();
  };

  useEffect(() => {
    fetchStudents();
    fetchClass();
  }, [classId, schoolId, refresh]);

  const fetchStudents = async () => {
    try {
      setLoading(true);
      const { data } = await userRequest.get(`children/class/${classId}?limit=200`);
      //console.log(data.children);
      setStudents(data.children);
      setLoading(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const fetchClass = async () => {
    try {
      //setLoading(true);
      const { data } = await userRequest.get(`classes/${schoolId}/${classId}`);
      //console.log(data);
      setClassInfo(data);
      //setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const ids = students?.map(child => child._id);
  //console.log(ids);

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!firstname || !lastname || !gender) {
      setError("Please Fill all the Feilds");
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
      return;
    }
    try {
      await userRequest.post("/children/create",
        { 
          school_code:schoolCode,
          class_id: classInfo._id, 
          firstname,
          lastname,
          gender,
        });
      //console.log(data);
      setRefresh(!refresh);
      setSuccess(true);
      setTimeout(() => {
        setSuccess(false);
        setFirstname("");
        setLastname("");
        setGender("");
        toggleModal();
      }, 2000);
    } catch (error) {
      console.log(error);
      setError(error.response.data);
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
    }
    setLoading(false);
  };

  const deleteHandler = async (studentId) => {
    try {
      const res = await userRequest.delete(`children/${studentId}`);
      //console.log(res.data);
      setStudents(res.data.classes)
    } catch (error) {
      console.log(error);
    }
  };
  
  const columns = [
    /*{
      field: "id",
      name: "Student ID",
      selector: row => <span className="font-bold">acc-stu-{row.id}</span>
    },*/
    {
      field: "Name",
      name: "First Name",
      selector: row => row.firstname
    },
    {
      field: "Name",
      name: "Last Name",
      selector: row => row.lastname
    },
    {
      field: "gender",
      name: "Gender",
      selector: row => row.gender
    },
    {
      field: "Fee Account",
      name: "Account (collection)",
      selector: row => row.account ? `${row.account.account_number} - ${row.account.name}` : "Account not assigned"
    },
    {
      cell: (row) => <Link to={`/update-student/${row._id}`} style={{ color: '#1e4db7', backgroundColor: '#91a6f5' }} className="text-md opacity-0.9 rounded-full p-3 hover:drop-shadow-xl"><FiEdit /></Link>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      cell: (row) => <button onClick={() => deleteHandler(row.id)} style={{ color: 'rgb(228, 106, 118)', backgroundColor: 'rgb(255, 244, 229)' }} className="text-md opacity-0.9 rounded-full p-3 hover:drop-shadow-xl"><FiTrash2 /></button>,
      ignoreRowClick: true,
      button: true,
    },
  ];

  const uploadHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!file) {
      setError("Please provide a file!");
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
      return;
    }

    try {
      let studentData = new FormData();
      studentData.append("file", file);
      studentData.append("school_code", schoolCode);
      studentData.append("class_id", classId);

      await userRequest.post("/children/upload", studentData );
      setSuccess(true);
      fetchStudents();
      setTimeout(() => {
        setSuccess(false);
      }, 5000);
    } catch (error) {
      console.log(error);
      setError(error.response.data);
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
    }
    setLoading(false);
  };

  const accountHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!ids) {
      setError("Student Info Still loading!");
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
      return;
    }

    try {
      await userRequest.post("/children/assign-account", 
      {
        school_code: schoolCode,
        class_id: classId,
        student_ids: ids
      });
      setAccountSuccess(true);
      fetchStudents();
      setTimeout(() => {
        setAccountSuccess(false);
      }, 5000);
    } catch (error) {
      console.log(error);
      setError(error.response.data);
      setTimeout(() => {
        setError("");
      }, 5000);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl dark:bg-secondary-dark-bg">
      <div className="flex flex-wrap justify-between items-center mb-4">
        <button onClick={goBack} className="inline-flex justify-center py-2 px-6 mb-2 md:mb-0 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          <i className="fad fa-arrow-left"></i>
        </button>
        <div className="flex flex-wrap justify-center md:justify-end w-full md:w-auto">
          <button type="button" className="inline-flex justify-center py-2 px-4 mx-2 mb-2 md:mb-0 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2">
            Download Template
          </button>
          <button type="button" onClick={toggleModal} className="inline-flex justify-center py-2 px-4 mb-2 md:mb-0 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2">
            Add New Student
          </button>
          <button type="button" onClick={() => setClicked(!clicked)} className="inline-flex justify-center py-2 px-4 mx-2 mb-2 md:mb-0 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2">
            Upload Students
          </button>
          {loading ? (
            <ButtonLoading styles="inline-flex justify-center py-2 px-4 mx-2 mb-2 md:mb-0 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600" />
          ) : (
            <button type="button" onClick={accountHandler} className="inline-flex justify-center py-2 px-4 mx-2 mb-2 md:mb-0 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2">
              Assign Accounts
            </button>
          )}
        </div>
      </div>
      <Header category="Students" title={classInfo?.class_name} />
      {clicked && (
        <div className="absolute right-4 z-10 bg-white border-2 shadow-lg rounded-lg p-4 w-100" style={{ top: "12rem" }}>
          <div className="flex justify-between items-center py-2 mb-2 border-b">
            <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
              Upload Students
            </h3>
            <button type="button"
              className="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={() => setClicked(!clicked)}
            >
              <i className="fal fa-times"></i>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="gap-6 mb-6 grid-cols-2">
            <form onSubmit={uploadHandler}>
              <div className="grid grid-cols-1 gap-6">
                {error && (
                  <div className="alert alert-warning flex items-center" role="alert">
                    <i className="fal fa-times pr-2"></i>
                    <div>{error}</div>
                  </div>
                )}
                {success && (
                  <div className="alert alert-success flex items-center" role="alert">
                    <i className="fal fa-check pr-2"></i>
                    <div>Student Upload Successful!</div>
                  </div>
                )}
                <label className="block">
                  <span className="text-gray-700 dark:text-gray-200">Upload list of Student <small>(format: CSV)</small></span>
                  <input type="file" id="file" accept=".csv" onChange={(e) => setFile(e.target.files[0])} className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" />
                </label>
                <div className="">
                  {loading ? (
                    <ButtonLoading styles="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-600" />
                  ) : (
                    <button type="submit" className="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      Upload
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      <DataTable
        data={students}
        columns={columns}
        getRowId={(row) => row._id}
        pagination
        paginationComponentOptions={{
          rowsPerPageText: 'Rows per page:',
          rangeSeparatorText: 'of',
          selectAllRowsItem: true,
          selectAllRowsItemText: 'All',
        }}
        paginationComponent={CustomPagination}
        paginationRowsPerPageOptions={[10, 20, 30, 50]}
        customStyles={customStyles}
        progressPending={loading}
        progressComponent={<ImSpinner className="animate-spin text-4xl text-gray-400" />}
      />
      {isModalOpen && 
        <AddStudentModal 
          closeModal={toggleModal} 
          firstname={firstname} 
          setFirstname={setFirstname}
          lastname={lastname}
          setLastname={setLastname}
          gender={gender}
          setGender={setGender}
          error={error}
          success={success}
          loading={loading} 
          submitHandler={submitHandler}
        />
      }
    </div>

  );
};
export default Students;
