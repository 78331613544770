import React, { useState } from 'react';
import { Header, ButtonLoading } from '../components';
import { userRequest } from '../utils/requestMethods';

const Compliance = () => {
    const [old_password, setOldPassword] = useState({});
    const [new_password, setNewPassword] = useState({});
    const [confirm_password, setConfirmPassword] = useState({});
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const submitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!old_password || !new_password || !confirm_password) {
            setError("Please Fill all the Feilds");
            setTimeout(() => {
                setError("");
            }, 5000);
            setLoading(false);
            return;
        }

        try {
            await userRequest.put("auth/change-password",
                {
                    old_password,
                    new_password,
                    confirm_password,
                });

            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
            }, 5000);
        } catch (error) {
            console.log(error);
            setError(error.response.data);
            setTimeout(() => {
                setError("");
            }, 5000);
            setLoading(false);
        }
        setLoading(false);
    };

    return (
        <div className="m-2 md:m-10 mt-24  p-2 md:p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
            <Header category="Profile" title="Account Setting" />
            <div className="mt-2 max-w-full divide-y">
                <div className="py-2">
                    <h3 className="text-1xl font-bold dark:text-gray-200">Change Password</h3>
                    <hr />
                </div>
            </div>
            <div className="py-2 items-right">
                <div className="mt-2 max-w-full">
                    <form onSubmit={submitHandler}>
                        {success &&
                            <div className="alert alert-success d-flex align-items-center" role="alert">
                                <i className="fal fa-check pr-10"></i>
                                <div>
                                    Password Updated Successfully!
                                </div>
                            </div>
                        }
                        {error &&
                            <div className="alert alert-warning d-flex align-items-center" role="alert">
                                <i className="fal fa-lock pr-10"></i>
                                <div>
                                    {error}
                                </div>
                            </div>
                        }
                        <div className="grid grid-cols-1 gap-6">
                            <label className="block">
                                <span className="text-gray-700 dark:text-gray-200">Old Password</span>
                                <input type="password" onChange={(e) => setOldPassword(e.target.value)} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                            </label>
                            <label className="block">
                                <span className="text-gray-700 dark:text-gray-200">New Password</span>
                                <input type="password" onChange={(e) => setNewPassword(e.target.value)} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                            </label>
                            <label className="block">
                                <span className="text-gray-700 dark:text-gray-200">Confirm Password</span>
                                <input type="password" onChange={(e) => setConfirmPassword(e.target.value)} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                            </label>
                            
                            <div className="px-4 py-3 text-right sm:px-6">
                                {loading ?
                                    (
                                        <ButtonLoading styles="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600" />
                                    ) : (
                                        <button type="submit" className="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Update info</button>
                                    )
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
export default Compliance;