import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
/*import { useFormik } from "formik";
import { informationSchema } from '../../utils/formSchema';
import { formatDate } from '../../utils/dateUtils';*/
import { userRequest } from '../../utils/requestMethods';
import { useStateContext } from '../../contexts/ContextProvider';
import ButtonLoading from '../ButtonLoading';

const Account = ({ accountInfo }) => {
    const { user } = useStateContext();
    const [banks, setBanks] = useState([]);
    const [bankCode, setBankCode] = useState("");
    const [account_name, setAccount_name] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    //console.log(banks);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    console.log(bankCode, account_name, accountNumber);

  useEffect(() => {
    const fetchBanks = async () => {
      try {
        const { data } = await userRequest.get(`anchor/banks`);
        setBanks(data.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchBanks();
  }, [user]);

  const resolveHandler = async (e) => {
    e.preventDefault();
    setBankCode(e.target.value);
    setLoading(true);

    try {
        const { data } = await userRequest.post(`/anchor/verify-account-number`, {
            bankCode: e.target.value,
            accountNumber
        });
        console.log(data);
        setAccount_name(data.attributes.accountName);
    } catch (error) {
        console.log(error);
    } finally {
        setLoading(false);
    }
};

const saveHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
 
    try {
       await userRequest.put(`/org/${user._id}`, {
        account_name: account_name,
        account_number: accountNumber,
        bank_code: bankCode
       });
 
       setSuccess("Account Information added");
       setTimeout(() => {
          setSuccess("");
          //navigate("/auth/kyc");
       }, 2000);
    } catch (error) {
       console.log(error);
       setError(error.response.data);
       setTimeout(() => {
          setError("");
       }, 5000);
    } finally {
     setLoading(false);
   }
 };

    return (
        <div>
            <div className="mt-2 max-w-xl mx-auto divide-y">
                <div className="py-2">
                    <h3 className="text-2xl font-bold dark:text-gray-200">Enter your business bank account number</h3>
                    <p>
                        Ensure the name on your bank account matches the legal business name you provided.
                    </p>
                    <hr />
                </div>
            </div>
            {accountInfo.account_name ? (
            <div className="mt-2 max-w-xl mx-auto divide-y p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-4 dark:bg-gray-800 dark:border-gray-700">
                <div className="flow-root">
                    <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                    <p className="text-base font-semibold text-gray-900 truncate dark:text-white">
                                        {accountInfo.account_name}
                                    </p>
                                    <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                        {accountInfo.bank_code}
                                    </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                    {accountInfo.account_number}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            ) : null }
            <div className="py-2 items-right">
                <div className="max-w-xl mx-auto mt-3 divide-y">
                    {success &&
                        <div className="alert alert-success d-flex align-items-center" role="alert">
                            <i className="fal fa-check pr-10"></i>
                            <div>
                                {success}
                            </div>
                        </div>
                    }
                    {error && 
                        <div className="alert alert-warning d-flex align-items-center" role="alert">
                            <i className="fal fa-lock pr-10"></i>
                            <div>
                                {error}
                            </div>
                        </div>
                    }
                    <form onSubmit={saveHandler}>
                        <div className="grid grid-cols-1 gap-6">
                            <label className="block">
                                <span className="text-gray-700 dark:text-gray-200">Bank Account Number <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">{account_name}</span></span>
                                <input type="text" id="accountNumber" onChange={(e) => setAccountNumber(e.target.value)} className="mt-1 block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" />
                            </label>
                            <label className="block">
                                <span className="text-gray-700 dark:text-gray-200">Bank Name</span>
                                <select id="bankCode" onChange={resolveHandler} className="bg-gray-100 border-gray-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" required="">
                                    <option value="">Select</option>
                                    {banks?.map((bank) => (
                                        <option key={bank.id} value={bank.attributes.nipCode}>
                                            {bank.attributes.name}
                                        </option>
                                    ))}
                                </select>
                            </label>
                            <div className="px-4 py-3 text-right sm:px-6">
                                {loading ?
                                    (
                                        <ButtonLoading styles="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-400" />
                                    ) : (
                                        <button type="submit" className="inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Save Details</button>
                                    )
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <hr />
            <div className="px-4 py-3 sm:px-6 mb-2">
                <Link to="/compliance/payment">
                    <button className="float-right inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Next</button>
                </Link>
                <Link to="/compliance/personal">
                    <button className="float-left inline-flex justify-center py-2 px-8 border border-transparent shadow-sm text-md font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Back</button>
                </Link>
            </div>
        </div>
    )
}

export default Account
