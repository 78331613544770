export function generateSchoolSessions(startYear, numberOfYears) {
    const sessions = [];
    
    // Loop to generate the sessions for the given number of years
    for (let i = 0; i < numberOfYears; i++) {
      const year1 = startYear + i;
      const year2 = startYear + i + 1;
      const session = `${year1}/${year2}`;
      sessions.push({ label: session, value: session });
    }
    
    return sessions;
}

export function displaySchoolTerm(term) {
    switch(term) {
        case 1:
            return "1st Term";
        case 2:
            return "2nd Term";
        case 3:
            return "3rd Term";
        default:
            return ""
    }
}
