import React, {useState} from 'react'
import { MdVpnKey } from 'react-icons/md';
import { userRequest } from '../utils/requestMethods';
import { ImSpinner } from 'react-icons/im';

const SendOTP = ({ school }) => {
    const [lead, setLead] = useState("2FA: Tap to Generate OTP!");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false);

    const sendOtpHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        setLead("2FA: Sending...");
    
        try {
            await userRequest.post(`/transactions/withdraw-otp/${school._id}`, {});
            //console.log(data);
            setLead("2FA: Tap to Generate OTP!");
            setSuccess(true);
            setTimeout(() => {
                setSuccess(false);
            }, 3000);
        } catch (error) {
            console.log(error);
            setError(true);
            setTimeout(() => {
                setError(false);
            }, 3000);
        } finally {
            setLead("2FA: Tap to Generate OTP!");
            setLoading(false);
        }
    };

  return (
    <div className="flex p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400" role="alert">
        {loading ?
            (
                <ImSpinner className="mr-2 animate-spin text-4xl text-blue-800" />
            ) : (
                <MdVpnKey className="mr-2 text-4xl font-bold" />
            )
        }
        <span className="sr-only">2FA: Tap to Generate OTP!</span>
        <div>
            <span className="font-bold">
                {lead} 
                {success &&
                    <span className="text-green-600 float-right">Success: Sent!</span>
                }
                {error && 
                    <span className="text-red-800 float-right">Error: Not sent!</span>
                }
            </span>
            <p className="mt-1.5 mb-1">
                For even better security, 2FA is now required for sensitive actions on your account. Please tap to generate OTP for this withdrawal then come back to proceed.
            </p>
            <button onClick={sendOtpHandler} className="inline-flex justify-center py-1 px-8 shadow-sm text-sm font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700">
                Generate OTP!
            </button>
        </div>
    </div>
  )
}

export default SendOTP