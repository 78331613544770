import React, { useState } from 'react';
//import { Link } from 'react-router-dom';
import { Header } from '../components';
import { useStateContext } from '../contexts/ContextProvider';

const Income = ({ history }) => {
    const { user, school } = useStateContext();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const goBack = () => {
        // Go back to the previous page
        history.goBack();
    };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

    return (
        <div className="m-4 md:m-10 mt-24 p-10 bg-white dark:bg-secondary-dark-bg rounded-3xl">
            <button onClick={goBack} className="inline-flex justify-center py-2 px-6 mb-2 border border-transparent shadow-sm text-lg font-medium rounded-full text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"><i class="fad fa-arrow-left"></i></button>
            <Header category="Financing" title="Invoice Financing" />
            <div class="w-full p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
                <h5 class="mb-4 text-center text-3xl font-bold text-gray-900 dark:text-white">Invoice</h5>
                <span className="text-sm font-bold">For:</span>
                <p class="text-md text-gray-500 sm:text-lg dark:text-gray-400">{school?.school_name}</p>
                <p class="text-md text-gray-500 sm:text-lg dark:text-gray-400">{user?.email}</p>
                <p class="mb-5 text-md text-gray-500 sm:text-lg dark:text-gray-400">{school?.school_address}</p>
                <hr className='mt-10 mb-10' />
                <div class="flex items-center justify-between mt-4 mb-4">
                    <h5 class="text-xl font-bold leading-none text-gray-900 dark:text-white">INVOICE BREAKDOWN</h5>
                </div>
                <div class="flow-root text-center mb-5">
                    <ul class="divide-y divide-gray-200 dark:divide-gray-700">
                        <li class="py-3 sm:py-4">
                            <div class="flex items-center space-x-4">
                                <div class="flex-shrink-0">
                                    <p class="text-md font-semibold text-gray-900 truncate dark:text-white">
                                    Transaction Size:
                                    </p>
                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                                        Amount from a single school term
                                    </p>
                                </div>
                                <div class="inline-flex items-center">
                                    <p class="text-sm font-semibold text-gray-900 truncate dark:text-white">
                                        ₦5,000,000
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="py-3 sm:py-4">
                            <div class="flex items-center space-x-4">
                                <div class="flex-shrink-0">
                                    <p class="text-sm font-semibold text-gray-900 truncate dark:text-white">
                                        Purchase Discount
                                    </p>
                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                                        % 
                                    </p>
                                </div>
                                <div class="inline-flex items-center">
                                    <p class="text-sm font-semibold text-gray-900 truncate dark:text-white">
                                        10%
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="py-3 sm:py-4">
                            <div class="flex items-center space-x-4">
                                <div class="flex-shrink-0">
                                    <p class="text-sm font-semibold text-gray-900 truncate dark:text-white">
                                        Purchase Tenure
                                    </p>
                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                                        - 
                                    </p>
                                </div>
                                <div class="inline-flex items-center">
                                    <p class="text-sm font-semibold text-gray-900 truncate dark:text-white">
                                        4 Months
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="py-3 sm:py-4">
                            <div class="flex items-center space-x-4">
                                <div class="flex-shrink-0">
                                    <p class="text-sm font-semibold text-gray-900 truncate dark:text-white">
                                        Purchase Value
                                    </p>
                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                                        Amount to be paid to you
                                    </p>
                                </div>
                                <div class="inline-flex items-center">
                                    <p class="text-sm font-semibold text-gray-900 truncate dark:text-white">
                                        ₦4,500,000
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="py-3 sm:py-4">
                            <div class="flex items-center space-x-4">
                                <div class="flex-shrink-0">
                                    <p class="text-sm font-semibold text-gray-900 truncate dark:text-white">
                                        Repayment Amount
                                    </p>
                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                                        Amount to repay
                                    </p>
                                </div>
                                <div class="inline-flex items-center">
                                    <p class="text-sm font-semibold text-gray-900 truncate dark:text-white">
                                        ₦5,000,000
                                    </p>
                                </div>
                            </div>
                        </li>
                        <li class="pt-3 pb-0 sm:pt-4">
                            <div class="flex items-center space-x-4">
                                <div class="flex-shrink-0">
                                    <p class="text-sm font-semibold text-gray-900 truncate dark:text-white">
                                        Total Receivable
                                    </p>
                                </div>
                                <div class="flex-1 min-w-0">
                                    <p class="text-sm font-medium text-gray-900 truncate dark:text-white">
                                        -
                                    </p>
                                </div>
                                <div class="inline-flex items-center">
                                    <p class="text-lg font-semibold text-gray-900 truncate dark:text-white">
                                        ₦4,500,000
                                    </p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className='mt-10 mb-10 text-center'>
                    <button onClick={toggleModal} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">
                        Accept & Proceed
                        <svg class="w-3.5 h-3.5 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                        </svg>
                    </button>
                </div>
                {isModalOpen && (
                <div id="defaultModal" className="fixed top-0 left-0 right-0 z-50 w-full h-full flex items-center justify-center bg-opacity-50 bg-gray-700 dark:bg-gray-800">
                    <div className="w-full max-w-2xl p-4 bg-white rounded-lg shadow dark:bg-gray-700">
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Terms of Service
                            </h3>
                            <button onClick={toggleModal} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="defaultModal">
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="p-6 space-y-6">
                            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                With less than a month to go before the European Union enacts new consumer privacy laws for its citizens, companies around the world are updating their terms of service agreements to comply.
                            </p>
                            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                The European Union’s General Data Protection Regulation (G.D.P.R.) goes into effect on May 25 and is meant to ensure a common set of data rights in the European Union. It requires organizations to notify users as soon as possible of high-risk data breaches that could personally affect them.
                            </p>
                        </div>
                        <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button onClick={toggleModal} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" data-modal-hide="defaultModal">
                                I accept
                            </button>
                            <button onClick={toggleModal} className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600" data-modal-hide="defaultModal">
                                Decline
                            </button>
                        </div>
                    </div>
                </div>
            )}
            </div>
        </div>
    )
}

export default Income
